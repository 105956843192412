import { DEVELOPER_EMPLOYEE_DELETE_REQUEST, DEVELOPER_EMPLOYEE_DELETE_SUCCESS, DEVELOPER_EMPLOYEE_EDIT_FAIL, DEVELOPER_EMPLOYEE_EDIT_REQUEST, DEVELOPER_EMPLOYEE_EDIT_SUCCESS, DEVELOPER_EMPLOYEE_LIST_FAIL, DEVELOPER_EMPLOYEE_LIST_REQUEST, DEVELOPER_EMPLOYEE_LIST_SUCCESS, DEVELOPER_EMPLOYEE_SAVE_FAIL, DEVELOPER_EMPLOYEE_SAVE_REQUEST, DEVELOPER_EMPLOYEE_SAVE_SUCCESS, DEVELOPER_EMPLOYEE_SAVE_VALIDATION, DEVELOPER_EMPLOYEE_UPDATE_FAIL, DEVELOPER_EMPLOYEE_UPDATE_REQUEST, DEVELOPER_EMPLOYEE_UPDATE_SUCCESS, DEVELOPER_EMPLOYEE_UPDATE_VALIDATION } from '../constants/developeremployeeConstants'
import axios from 'axios'

//=================================  Developer  ============================================

export const developeremployeeList = (page, search) => async (dispatch) => {
  try {
    dispatch({
      type: DEVELOPER_EMPLOYEE_LIST_REQUEST,
    })

    const { data } = await axios.get(`developer-employee`, {
      params: {
        'page' : page,
        'search': search
      }
    })

    dispatch({
      type: DEVELOPER_EMPLOYEE_LIST_SUCCESS,
      payload: data
    })
  } catch (e) {
    const err = e.response && e.response.data.message ? e.response.data.message : e.message;
    dispatch({
      type: DEVELOPER_EMPLOYEE_LIST_FAIL,
      payload: err          
    })
  }
}

export const developeremployeeSave = (name, email, no_telepon, address,password,retype_password,developer_id,history) => async (dispatch) => {
  try {
    dispatch({
      type: DEVELOPER_EMPLOYEE_SAVE_REQUEST
    })

    const config = { 
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };

    const formData = new FormData()
    formData.append('name', name)
    formData.append('email', email)
    formData.append('no_telepon', no_telepon)
    formData.append('address', address)
    formData.append('password', password)
    formData.append('retype_password', retype_password)
    formData.append('developer_id', developer_id)

    await axios.post('developer-employee',formData,config)

    dispatch({
      type: DEVELOPER_EMPLOYEE_SAVE_SUCCESS,
    })

    history.push('/developer-employee-index')
    
  } catch (e) {
    if (e.response.status === 422) {
      dispatch({
        type: DEVELOPER_EMPLOYEE_SAVE_VALIDATION,
        payload: e.response.data.errors
      })
    } else {
      const err = e.response && e.response.data.message ? e.response.data.message : e.message;
      dispatch({
        type: DEVELOPER_EMPLOYEE_SAVE_FAIL,
        payload: err          
      })
    }
  }
}

export const developeremployeeEdit = (id) => async (dispatch) => {
  try {
    dispatch({
      type: DEVELOPER_EMPLOYEE_EDIT_REQUEST,
    })

    const { data } = await axios.get(`developer-employee/${id}/edit`)

    dispatch({
      type: DEVELOPER_EMPLOYEE_EDIT_SUCCESS,
      payload: data
    })
  } catch (e) {
    const err = e.response && e.response.data.message ? e.response.data.message : e.message;
    dispatch({
      type: DEVELOPER_EMPLOYEE_EDIT_FAIL,
      payload: err          
    })
  }
}

export const developeremployeeUpdate = (name, email, no_telepon, address,developer_id,password, id, history) => async (dispatch) => {
  try {
    dispatch({
      type: DEVELOPER_EMPLOYEE_UPDATE_REQUEST
    })

    const config = { 
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };
    
    const formData = new FormData()
    formData.append('name', name)
    formData.append('email', email)
    formData.append('no_telepon', no_telepon)
    formData.append('address', address)
    formData.append('developer_id', developer_id)
    formData.append('password', password)
    formData.append('_method', 'PUT')

    await axios.post(`developer-employee/${id}`,formData,config)

    dispatch({
      type: DEVELOPER_EMPLOYEE_UPDATE_SUCCESS,
    })
    history.push('/developer-employee-index')
    
  } catch (e) {
    if (e.response.status === 422) {
      dispatch({
        type: DEVELOPER_EMPLOYEE_UPDATE_VALIDATION,
        payload: e.response.data.errors
      })
    } else {
      const err = e.response && e.response.data.message ? e.response.data.message : e.message;
      dispatch({
        type: DEVELOPER_EMPLOYEE_UPDATE_FAIL,
        payload: err          
      })
    }
  }
}

export const developeremployeeDelete = (id) => async (dispatch) => {
  try {
    dispatch({
      type: DEVELOPER_EMPLOYEE_DELETE_REQUEST
    })

    await axios.delete(`developer-employee/${id}`)

    dispatch({
      type: DEVELOPER_EMPLOYEE_DELETE_SUCCESS
    })
  } catch (e) {
    const err = e.response && e.response.data.message ? e.response.data.message : e.message;
    dispatch({
      type: DEVELOPER_EMPLOYEE_DELETE_REQUEST,
      payload: err          
    })
  }
}