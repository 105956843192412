import { 
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGIN_VALIDATION,
  USER_LIST_REQUEST,
  USER_LIST_SUCCESS,
  USER_LIST_FAIL,
  LOGOUT_REQUEST,
  LOGOUT_SUCCESS,
  LOGOUT_FAIL,
  PROFILE_REQUEST,
  PROFILE_SUCCESS,
  PROFILE_FAIL,
  USER_SAVE_REQUEST,
  USER_SAVE_SUCCESS,
  USER_SAVE_VALIDATION,
  USER_SAVE_FAIL,
  USER_EDIT_REQUEST,
  USER_EDIT_SUCCESS,
  USER_EDIT_FAIL,
  USER_UPDATE_REQUEST,
  USER_UPDATE_SUCCESS,
  USER_UPDATE_VALIDATION,
  USER_UPDATE_FAIL,
  USER_DELETE_REQUEST,
  USER_DELETE_SUCCESS,
  USER_DELETE_FAIL
} from '../constants/authConstants'
import { message } from 'antd'

const key = 'updatable';

const userInfoFromStorage = localStorage.getItem('userInfo') 
  ? JSON.parse(localStorage.getItem('userInfo'))
  : null

const initialState = {
  userInfo: userInfoFromStorage,
  validation: [],
  error: []
}

export const loginReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_REQUEST:
      return { ...state, loading: true, error: [], validation: [] }
    case LOGIN_VALIDATION:
      return { ...state, loading: false, validation: action.payload }
    case LOGIN_SUCCESS:
      return { ...state,loading: false, userInfo: action.payload }
    case LOGIN_FAIL:
      return { ...state, loading: false, error: action.payload }
    default:
      return state
  }
}

export const logoutReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGOUT_REQUEST:
      message.loading('Loading...', key);
      return{...state, loading: true}
    case LOGOUT_SUCCESS:
      message.success({ content: 'Loggout success', key });
      return{...state, loading: false, userInfo: null}
    case LOGOUT_FAIL:
      message.success({ content: 'Insert failed', key });
      return{...state, loading: false, error: action.payload}
    default:
      return state
  }
}

export const profileReducer = (state = {profile: []}, action) => {
  switch (action.type) {
    case PROFILE_REQUEST:
      return{...state, loading: true}
    case PROFILE_SUCCESS:
      return{...state, loading: false, profile: action.payload}
    case PROFILE_FAIL:
      return{...state, loading: false, error: action.payload}
    default:
      return state
  }
}

//=================================  User  ============================================

export const userListReducer = (state = {user:[], listsUser:[]}, action) => {
  switch (action.type) {
    case USER_LIST_REQUEST:
      return{ ...state, loading: true, error: [] }
    case USER_LIST_SUCCESS:
      return{ ...state, loading: false, user: action.payload.data, listsUser: action.payload.data.data }
    case USER_LIST_FAIL:
      return{ ...state, loading: false, error: action.payload }
    default:
      return state
  }
}

export const userSaveReducer = (state = initialState, action) => {
  switch (action.type) {
    case USER_SAVE_REQUEST:
      message.loading('Loading...', key);
      return{ ...state, loading: true, error: [], validation: [] }
    case USER_SAVE_SUCCESS:
      message.success({ content: 'Insert success', key });
      return{ ...state, loading: false }
    case USER_SAVE_VALIDATION:
      message.error({ content: 'Please fill all required field', key });
      return{ ...state, loading: false, validation: action.payload }
    case USER_SAVE_FAIL:
      message.error({ content: action.payload, key });
      return{ ...state, loading: false, error: action.payload }
    default:
      return state
  }
}

export const userEditReducer = (state = {user:{}}, action) => {
  switch (action.type) {
    case USER_EDIT_REQUEST:
      return{ ...state, loading: true, error: [] }
    case USER_EDIT_SUCCESS:
      return{ ...state, loading: false, user: action.payload.data }
    case USER_EDIT_FAIL:
      return{ ...state, loading: false, error: action.payload }
    default:
      return state
  }
}

export const userUpdateReducer = (state = initialState, action) => {
  switch (action.type) {
    case USER_UPDATE_REQUEST:
      message.loading('Loading...', key);
      return{ ...state, loading: true, error: [], validation: [] }
    case USER_UPDATE_SUCCESS:
      message.success({ content: 'Update success', key });
      return{ ...state, loading: false }
    case USER_UPDATE_VALIDATION:
      message.error({ content: 'Please fill all required field', key });
      return{ ...state, loading: false, validation: action.payload }
    case USER_UPDATE_FAIL:
      message.error({ content: action.payload, key });
      return{ ...state, loading: false, error: action.payload }
    default:
      return state
  }
}

export const userDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_DELETE_REQUEST:
      message.loading('Loading...', key);
      return{ ...state, loading: true, error: [] }
    case USER_DELETE_SUCCESS:
      message.success({ content: 'Delete success', key });
      return{ ...state, loading: false }
    case USER_DELETE_FAIL:
      message.error({ content: action.payload, key });
      return{ ...state, loading: false, error: action.payload }
    default:
      return state
  }
}