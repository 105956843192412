import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Popconfirm, message, Empty, Modal, Input } from 'antd'
import { asyncGetAllPromoCategories, asyncAddPromoCategories, asyncDeletePromoCategories, asyncEditPromoCategories } from '../../../store/actions/promoActions'

function PromoCategory() {
  const [pageNumber, setPageNumber] = useState(1);
  const [promoCategoryName, setPromoCategoryName] = useState('');
  const [editedPromoCategoryName, setEditedPromoCategoryName] = useState('');
  const [editedId, setEditedId] = useState('');
  const [showModalAddCategory, setShowModalCategory] = useState(false);
  const [showModalEditCategory, setEditModalCategory] = useState(false);
  
  let dispatch = useDispatch()

  useEffect(() => {
    dispatch(asyncGetAllPromoCategories(pageNumber));
  }, [dispatch])

  const dataPromoCategories = useSelector(state => state.promoCategories);
  console.log(dataPromoCategories)

  const dataLogin = useSelector(state => state.login)
  let { userInfo } = dataLogin

  const handleAddPromoCategory = async () => {
    await dispatch(asyncAddPromoCategories(promoCategoryName, pageNumber));
    setShowModalCategory(false);
    setPromoCategoryName('');
  }

  const handleShowModalEditPromoCategory = (id, name) => {
    setEditedPromoCategoryName(name);
    setPromoCategoryName(name);
    setEditedId(id);
    setEditModalCategory(true);
  }

  const handleEditPromoCategory = async () => {
    await dispatch(asyncEditPromoCategories(editedId, promoCategoryName, pageNumber));
    setEditModalCategory(false);
    setEditedPromoCategoryName('');
    setPromoCategoryName('');
    setEditedId('');
  }

  const handlePageChange = (e, pageNumber) => {
    e.preventDefault();
    setPageNumber(pageNumber);
    dispatch(asyncGetAllPromoCategories(pageNumber));
  }

  const listsPaginate = (jumlah_page, current_page) => {
    let item = [];
    for (let index = 1; index <= jumlah_page; index++) {
      item.push(index);
    }
    return item.map((page) => {
      if (current_page === 1) {
        if (page === current_page || page === parseInt(current_page) + 1) {
          return(
            <li className={(current_page === page ? "page-item active" : "page-item")}><a className="page-link" href="#" onClick={(e) => handlePageChange(e, page)}>{page}</a></li>
          );
        }
      } else if (current_page === jumlah_page) {
        if (page === current_page || page === parseInt(current_page) - 1) {
          return(
            <li className={(current_page === page ? "page-item active" : "page-item")}><a className="page-link" href="#" onClick={(e) => handlePageChange(e, page)}>{page}</a></li>
          );
        }
      } else {
        if (page === parseInt(current_page) - 1 || page === current_page || page === parseInt(current_page) + 1) {
          return(
            <li className={(current_page === page ? "page-item active" : "page-item")}><a className="page-link" href="#" onClick={(e) => handlePageChange(e, page)}>{page}</a></li>
          );
        }
      }
    })
  }

  return (
    <div className="">
      <div className="page-title">
        <div className="title_left">
          <h3>Promo Category</h3>
        </div>
      </div>
      <div className="clearfix"></div><br/>
      <div className="row">
        <div className="col-md-12 col-sm-12 col-xs-12">
          <div className="x_panel">
            <div className="x_title">
              <div className="row">
                <div className="col-md-6 col-sm-12 col-xs-12">
                  {
                    userInfo.user.roles[0].name === 'Super Admin'
                    ?
                      <button onClick={() => setShowModalCategory(true)} className="btn btn-info btn-sm">Create New Category</button>
                    :
                      null
                  }
                </div>
              </div>
              <div className="clearfix"></div>
            </div>
            
            <div className="x_content">
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th>Id Category</th>
                    <th>Category Name</th>
                    <th>Publish Time</th>
                    <th style={{ textAlign:'center' }}>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    (
                      dataPromoCategories.length < '1' ?
                        <tr>
                          <th colSpan="6"><Empty image={Empty.PRESENTED_IMAGE_SIMPLE} /></th>
                        </tr>
                      :
                        dataPromoCategories.data.map((item, index) => {
                          return(
                            <tr key={item.id}>
                              <th scope="row">{ item.id }</th>
                              <td>{item.title}</td>
                              <td>{new Date(item.created_at).toLocaleString('en-US', { timeZone: 'Asia/Jakarta' })}</td>
                              <td align="center">
                                {
                                  userInfo.user.roles[0].name === 'Super Admin'
                                  ?
                                    <button onClick={() => handleShowModalEditPromoCategory(item.id, item.title)} className="btn btn-warning btn-sm"><i className="fa fa-pencil"></i> Edit </button>
                                  :
                                    null
                                }
                                {
                                  userInfo.user.roles[0].name === 'Super Admin'
                                  ?
                                    <Popconfirm
                                      title="Are you sure to delete this promo category?"
                                      onConfirm={() => dispatch(asyncDeletePromoCategories(item.id, pageNumber)) }
                                      onCancel={() => message.info('Canceled!')}
                                      okText="Yes"
                                      cancelText="No"
                                    >
                                      <button className="btn btn-danger btn-sm"><i className="fa fa-trash-o"></i> Delete </button>
                                    </Popconfirm>
                                  :
                                    null
                                }
                              </td>
                            </tr>
                          )
                        })
                    )
                  }
                </tbody>
              </table>
              <nav aria-label="Page navigation example">
                <ul className="pagination justify-content-end">
                  <li className={( dataPromoCategories.prev_page_url === null ? "page-item disabled" : "page-item" )}>
                    <a className="page-link" href="#" onClick={(e) => handlePageChange(e, parseInt(dataPromoCategories.current_page) - 1)}  tabindex="-1">Previous</a>
                  </li>
                  {
                    listsPaginate(dataPromoCategories.last_page, dataPromoCategories.current_page)
                  }
                  <li className={( dataPromoCategories.next_page_url === null ? "page-item disabled" : "page-item" )}>
                    <a className="page-link" href="#" onClick={(e) => handlePageChange(e, parseInt(dataPromoCategories.current_page) + 1)} >Next</a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
      <Modal title="Add Promo Category" okText={'Submit'} open={showModalAddCategory} onCancel={() => setShowModalCategory(false)} onOk={handleAddPromoCategory} >
        <Input placeholder="Category name" value={promoCategoryName} onChange={(e) => setPromoCategoryName(e.target.value)} />
      </Modal>
      <Modal title="Edit Promo Category" okText={'Update'} open={showModalEditCategory} onCancel={() => setEditModalCategory(false)} onOk={handleEditPromoCategory} >
        <p className='mx-auto' style={{width:"fit-content"}}>Category Sebelumnya: <b>{editedPromoCategoryName}</b></p>
        <Input placeholder="Category name" value={promoCategoryName} onChange={(e) => setPromoCategoryName(e.target.value)} />
      </Modal>
    </div>
  )
}

export default PromoCategory