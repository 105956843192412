import React, { useState, useEffect, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { careerEdit, careerUpdate } from '../../../store/actions/websiteActions'
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Upload, message } from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import MyCustomUploadAdapterPlugin from '../../../components/UploadFileCK'

const CareerEdit = () => {
  const [type, settype] = useState('')
  const [title, settitle] = useState('')
  const [link, setlink] = useState('')
  const [location, setlocation] = useState('')
  const [content, setcontent] = useState('')
  const [contentplain, setcontentplain] = useState('')
  const [status, setstatus] = useState('')
  const [file, setfile] = useState([])
  const [image, setimage] = useState()
  const [loading, setloading] = useState(false)

  let dispatch = useDispatch()
  let history = useHistory()
  const id = localStorage.getItem('id_career');

  useEffect(() => {
    dispatch(careerEdit(id))
  }, [dispatch, id])

  const datacareerUpdate = useSelector(state => state.careerUpdate)
  const { validation } = datacareerUpdate

  const dataLogin = useSelector(state => state.login)
  const { userInfo } = dataLogin
  const updated_by = userInfo.user.id

  const datacareerEdit = useSelector(state => state.careerEdit)
  const { career } = datacareerEdit

  useMemo(() => {
    if (career) {
      settype(career.type)
      settitle(career.title)
      setlink(career.link)
      setlocation(career.location)
      setstatus(career.active)
      setcontent(career.content)
      setcontentplain(career.content_plain)
      setimage('https://apiv2.loanmarket.co.id/'+career.image_path)
      // setimage('http://api_v2.test/'+career.image_path)
    }
  }, [career])

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(careerUpdate(link, status, type, title, location, content, contentplain, file, updated_by, id, history))
  }

  function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  }
  
  function beforeUpload(file) {
    setloading(true)
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error('Image must smaller than 2MB!');
    }
    return isJpgOrPng && isLt2M;
  }

  const handleChange = (info) => {
    setfile(info.file.originFileObj)
    getBase64(info.file.originFileObj, imageUrl =>
      setimage(imageUrl),
      setloading(false)
    );
  };

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );


  return (
    <div className="">
      <div className="page-title">
        <div className="title_left">
          <h3>Edit Career</h3>
        </div>
      </div>
      <div className="clearfix"></div><br/>
      <div className="row">
        <div className="col-md-6 col-sm-6 col-xs-12">
          <div className="x_panel">
            <div className="x_title">
              <h2>Form Input</h2>
              <div className="clearfix"></div>
            </div>
            
            <div className="x_content">  
            <form onSubmit={(e) => handleSubmit(e)}>
                <div className="form-group">
                  <label for="name">Job Position</label>
                  <input value={title} type="text" className={ validation.length !== 0 && validation['title'] ? 'form-control parsley-error' : 'form-control' }
                  required onChange={(e) => settitle(e.target.value)} />
                  {
                    validation.length !== 0 
                    ?
                      <ul className="parsley-errors-list filled">
                        <li className="parsley-required">{validation['title']}</li>
                      </ul>
                    :
                      null
                  }
                </div>

                <div className="form-group">
                  <label for="name">Job Dekskription</label>
                    <CKEditor
                      editor={ ClassicEditor }
                      data={content}
                      config={{
                        extraPlugins: [ MyCustomUploadAdapterPlugin ],
                        // ckfinder: {
                        //   uploadUrl: '/ckfinder/core/connector/php/connector.php?command=QuickUpload&type=Files&responseType=json'
                        // }
                      }}
                      onChange={ ( event, editor ) => {
                          const data = editor.getData();
                          var plainString = data.replace(/<[^>]+>/g, '');
                          setcontent(data)
                          setcontentplain(plainString)
                          // console.log( { event, editor, data, plainString } );
                      } }
                    />
                  {
                    validation.length !== 0 
                    ?
                      <ul className="parsley-errors-list filled">
                        <li className="parsley-required">{validation['content']}</li>
                      </ul>
                    :
                      null
                  }
                </div>

                <div className="row">
                  <div className="col-md-6 col-sm-6 col-xs-12">
                    <div className="form-group">
                      <label for="name">Location</label>
                      <input value={location} type="text" 
                        className={ validation.length !== 0 && validation['location'] ? 'form-control parsley-error' : 'form-control' }
                        onChange={(e) => setlocation(e.target.value)}
                      />
                      {
                        validation.length !== 0 
                        ?
                          <ul className="parsley-errors-list filled">
                            <li className="parsley-required">{validation['location']}</li>
                          </ul>
                        :
                          null
                      }
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-6 col-xs-12">
                    <div className="form-group">
                      <label for="name">Type</label>
                      <input value={type} type="text" 
                        className={ validation.length !== 0 && validation['type'] ? 'form-control parsley-error' : 'form-control' }
                        onChange={(e) => settype(e.target.value)}
                      />
                      {
                        validation.length !== 0 
                        ?
                          <ul className="parsley-errors-list filled">
                            <li className="parsley-required">{validation['type']}</li>
                          </ul>
                        :
                          null
                      }
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6 col-sm-6 col-xs-12">
                    <div className="form-group">
                      <label for="name">Registration Link</label>
                      <input value={link} type="text" className={ validation.length !== 0 && validation['link'] ? 'form-control parsley-error' : 'form-control' }
                      required onChange={(e) => setlink(e.target.value)} />
                      {
                        validation.length !== 0 
                        ?
                          <ul className="parsley-errors-list filled">
                            <li className="parsley-required">{validation['link']}</li>
                          </ul>
                        :
                          null
                      }
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-6 col-xs-12">
                    <div className="form-group">
                      <label for="name">Status</label>
                      <select 
                        className={ validation.length !== 0 && validation['status'] ? 'form-control parsley-error' : 'form-control' }
                        required onChange={(e) => setstatus(e.target.value)} value={status}
                      >
                        <option disabled selected value="">Select Status</option>
                        <option value="1">Active</option>
                        <option value="0">Deactive</option>
                      </select>
                      {
                        validation.length !== 0 
                        ?
                          <ul className="parsley-errors-list filled">
                            <li className="parsley-required">{validation['status']}</li>
                          </ul>
                        :
                          null
                      }
                    </div>
                  </div>
                </div>

                <div className="form-group">
                  <label for="name">Image</label>
                  <Upload
                    name="avatar"
                    listType="picture-card"
                    className="avatar-uploader"
                    showUploadList={false}
                    beforeUpload={beforeUpload}
                    onChange={handleChange}
                  >
                    {image ? <img src={image} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
                  </Upload>
                  {
                    validation.length !== 0 
                    ?
                      <ul className="parsley-errors-list filled">
                        <li className="parsley-required">{validation['image']}</li>
                      </ul>
                    :
                      null
                  }
                </div>
                <button type="submit" className="btn btn-primary pull-right">Submit</button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CareerEdit
