import { message } from 'antd'
import { OFFICE_DELETE_FAIL, OFFICE_DELETE_REQUEST, OFFICE_DELETE_SUCCESS, OFFICE_EDIT_FAIL, OFFICE_EDIT_REQUEST, OFFICE_EDIT_SUCCESS, OFFICE_LIST_FAIL, OFFICE_LIST_REQUEST, OFFICE_LIST_SUCCESS, OFFICE_SAVE_FAIL, OFFICE_SAVE_REQUEST, OFFICE_SAVE_SUCCESS, OFFICE_SAVE_VALIDATION, OFFICE_UPDATE_FAIL, OFFICE_UPDATE_REQUEST, OFFICE_UPDATE_SUCCESS, OFFICE_UPDATE_VALIDATION } from '../constants/officeConstants';

const initialState = {
  validation: [],
}

const key = 'updatable';

//=================================  Office  ============================================

export const officeListReducer = (state = {office:[], listsOffice:[]}, action) => {
  switch (action.type) {
    case OFFICE_LIST_REQUEST:
      return{ ...state, loading: true, error: [] }
    case OFFICE_LIST_SUCCESS:
      return{ ...state, loading: false, office: action.payload.data, listsOffice: action.payload.data.data }
    case OFFICE_LIST_FAIL:
      return{ ...state, loading: false, error: action.payload }
    default:
      return state
  }
}

export const officeSaveReducer = (state = initialState, action) => {
  switch (action.type) {
    case OFFICE_SAVE_REQUEST:
      message.loading('Loading...', key);
      return{ ...state, loading: true, error: [], validation: [] }
    case OFFICE_SAVE_SUCCESS:
      message.success({ content: 'Insert success', key });
      return{ ...state, loading: false }
    case OFFICE_SAVE_VALIDATION:
      message.error({ content: 'Please fill all required field', key });
      return{ ...state, loading: false, validation: action.payload }
    case OFFICE_SAVE_FAIL:
      message.error({ content: action.payload, key });
      return{ ...state, loading: false, error: action.payload }
    default:
      return state
  }
}

export const officeEditReducer = (state = {office:{}}, action) => {
  switch (action.type) {
    case OFFICE_EDIT_REQUEST:
      return{ ...state, loading: true, error: [] }
    case OFFICE_EDIT_SUCCESS:
      return{ ...state, loading: false, office: action.payload.data }
    case OFFICE_EDIT_FAIL:
      return{ ...state, loading: false, error: action.payload }
    default:
      return state
  }
}

export const officeUpdateReducer = (state = initialState, action) => {
  switch (action.type) {
    case OFFICE_UPDATE_REQUEST:
      message.loading('Loading...', key);
      return{ ...state, loading: true, error: [], validation: [] }
    case OFFICE_UPDATE_SUCCESS:
      message.success({ content: 'Update success', key });
      return{ ...state, loading: false }
    case OFFICE_UPDATE_VALIDATION:
      message.error({ content: 'Please fill all required field', key });
      return{ ...state, loading: false, validation: action.payload }
    case OFFICE_UPDATE_FAIL:
      message.error({ content: action.payload, key });
      return{ ...state, loading: false, error: action.payload }
    default:
      return state
  }
}

export const officeDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case OFFICE_DELETE_REQUEST:
      message.loading('Loading...', key);
      return{ ...state, loading: true, error: [] }
    case OFFICE_DELETE_SUCCESS:
      message.success({ content: 'Delete success', key });
      return{ ...state, loading: false }
    case OFFICE_DELETE_FAIL:
      message.error({ content: action.payload, key });
      return{ ...state, loading: false, error: action.payload }
    default:
      return state
  }
}