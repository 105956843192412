import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { topperformerSave } from '../../../../store/actions/websiteActions'
import { DatePicker, Upload, message } from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';

const ChildCreate = () => {
  const [month, setmonth] = useState('')
  const [publish, setpublish] = useState('')
  const [image1, setimage1] = useState('')
  const [file1, setfile1] = useState([])
  const [image2, setimage2] = useState('')
  const [file2, setfile2] = useState([])
  const [loading, setloading] = useState(false)

  let dispatch = useDispatch()
  let history = useHistory()

  const datatopperformerSave = useSelector(state => state.topperformerSave)
  const { validation } = datatopperformerSave

  const dataLogin = useSelector(state => state.login)
  const { userInfo } = dataLogin
  const created_by = userInfo.user.id

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(topperformerSave(month, publish, file1, file2, created_by, history))
  }

  function onChangemonth(date, dateString) {
    setmonth(dateString)
  }

  function onChangepublish(date, dateString) {
    setpublish(dateString);
  }
  
  function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  }
  
  function beforeUploadimage1(file1) {
    setloading(true)
    const isJpgOrPng = file1.type === 'image/jpeg' || file1.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!');
    }
    const isLt2M = file1.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error('Image must smaller than 2MB!');
    }
    return isJpgOrPng && isLt2M;
  }

  function beforeUploadimage2(file2) {
    setloading(true)
    const isJpgOrPng = file2.type === 'image/jpeg' || file2.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!');
    }
    const isLt2M = file2.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error('Image must smaller than 2MB!');
    }
    return isJpgOrPng && isLt2M;
  }

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  const handleChangeImage1 = (info) => {
    setfile1(info.file.originFileObj)
    getBase64(info.file.originFileObj, imageUrl =>
      setimage1(imageUrl),
      setloading(false)
    );
  };

  const handleChangeImage2 = (info) => {
    setfile2(info.file.originFileObj)
    getBase64(info.file.originFileObj, imageUrl =>
      setimage2(imageUrl),
      setloading(false)
    );
  };

  return (
    <div className="">
      <div className="page-title">
        <div className="title_left">
          <h3>Create Top Performer</h3>
        </div>
      </div>
      <div className="clearfix"></div><br/>
      <div className="row">
        <div className="col-md-6 col-sm-6 col-xs-12">
          <div className="x_panel">
            <div className="x_title">
              <h2>Form Input</h2>
              <div className="clearfix"></div>
            </div>
            
            <div className="x_content">  
              <form onSubmit={(e) => handleSubmit(e)}>
                <div className='row'>
                  <div className="col-md-6 col-sm-6 col-xs-12">
                    <div className="form-group">
                      <label>Month Top Performer</label>
                      <br/>
                      <input type='month' className={ validation.length !== 0 && validation['month'] ? 'form-control parsley-error' : 'form-control' }
                        onChange={(e) => setmonth(e.target.value)} value={month} />
                      {
                        validation.length !== 0 
                        ?
                          <ul className="parsley-errors-list filled">
                            <li className="parsley-required">{validation['month']}</li>
                          </ul>
                        :
                          null
                      }
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-6 col-xs-12">
                    <div className="form-group">
                      <label>Publish Date</label>
                      <br/>
                      <input type='date' className={ validation.length !== 0 && validation['publish'] ? 'form-control parsley-error' : 'form-control' }
                        onChange={(e) => setpublish(e.target.value)} value={publish} />
                      {
                        validation.length !== 0 
                        ?
                          <ul className="parsley-errors-list filled">
                            <li className="parsley-required">{validation['publish']}</li>
                          </ul>
                        :
                          null
                      }
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 col-sm-6 col-xs-12">
                    <div className="form-group">
                      <label for="name">Winner Loan Adviser</label>
                      <Upload
                        name="avatar"
                        listType="picture-card"
                        className="avatar-uploader"
                        showUploadList={false}
                        beforeUpload={beforeUploadimage1}
                        onChange={handleChangeImage1}
                      >
                        {image1 ? <img src={image1} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
                      </Upload>
                      {
                        validation.length !== 0 
                        ?
                          <ul className="parsley-errors-list filled">
                            <li className="parsley-required">{validation['image1']}</li>
                          </ul>
                        :
                          null
                      }
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-6 col-xs-12">
                    <div className="form-group">
                      <label for="name">Winner Office</label>
                      <Upload
                        name="avatar"
                        listType="picture-card"
                        className="avatar-uploader"
                        showUploadList={false}
                        beforeUpload={beforeUploadimage2}
                        onChange={handleChangeImage2}
                      >
                        {image2 ? <img src={image2} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
                      </Upload>
                      {
                        validation.length !== 0 
                        ?
                          <ul className="parsley-errors-list filled">
                            <li className="parsley-required">{validation['image2']}</li>
                          </ul>
                        :
                          null
                      }
                    </div>
                    
                  </div>
                </div>
                <button type="submit" className="btn btn-primary pull-right">Submit</button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ChildCreate
