import { ActionType } from '../actions/promoActions';

export function promoCategoriesReducer(promoCategories = [], action = {}) {
  switch (action.type) {
    case ActionType.GET_PROMO_CATEGORIES:
      return action.payload.promo_categories;
    default:
      return promoCategories;
  }
}

export function promoReducer(promo = [], action = {}) {
  switch (action.type) {
    case ActionType.GET_PROMO:
      return action.payload.promo;
    default:
      return promo;
  }
}

export function assetPromoCategoryReducer(assetPromoCategory = [], action = {}) {
  switch (action.type) {
    case ActionType.GET_PROMO_CATEGORY_ASSET:
      return action.payload.asset_promo_category;
    default:
      return assetPromoCategory;
  }
}