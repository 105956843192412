import React from 'react'
import { BrowserRouter as Router, Switch } from 'react-router-dom'
import { useSelector }  from 'react-redux'
import Routes from './routes'
import Sidebar from './components/Sidebar'
import Navbar from './components/Navbar'

const App = () => {
  let dataLogin = useSelector(state => state.login)
  let { userInfo } = dataLogin
  return (
    <Router>
      <div className="container body">
        <div className="main_container">
          {
            userInfo ? (
              <>
              <Sidebar/>
              <Navbar/>
              <Switch>
                <div className="right_col" role="main">
                  <Routes/>
                </div>
              </Switch>
            </>
            ) : (
              <Switch>
                <Routes/>
              </Switch>
            )
          }
        </div>
      </div>
    </Router>
  )
}

export default App
