//=================================  DEVELOPER  ============================================

export const DEVELOPER_EMPLOYEE_LIST_REQUEST = 'DEVELOPER_EMPLOYEE_LIST_REQUEST'
export const DEVELOPER_EMPLOYEE_LIST_SUCCESS = 'DEVELOPER_EMPLOYEE_LIST_SUCCESS'
export const DEVELOPER_EMPLOYEE_LIST_FAIL = 'DEVELOPER_EMPLOYEE_LIST_FAIL'

export const DEVELOPER_EMPLOYEE_SAVE_REQUEST = 'DEVELOPER_EMPLOYEE_SAVE_REQUEST'
export const DEVELOPER_EMPLOYEE_SAVE_SUCCESS = 'DEVELOPER_EMPLOYEE_SAVE_SUCCESS'
export const DEVELOPER_EMPLOYEE_SAVE_FAIL = 'DEVELOPER_EMPLOYEE_SAVE_FAIL'
export const DEVELOPER_EMPLOYEE_SAVE_VALIDATION = 'DEVELOPER_EMPLOYEE_SAVE_VALIDATION'

export const DEVELOPER_EMPLOYEE_EDIT_REQUEST = 'DEVELOPER_EMPLOYEE_EDIT_REQUEST'
export const DEVELOPER_EMPLOYEE_EDIT_SUCCESS = 'DEVELOPER_EMPLOYEE_EDIT_SUCCESS'
export const DEVELOPER_EMPLOYEE_EDIT_FAIL = 'DEVELOPER_EMPLOYEE_EDIT_FAIL'

export const DEVELOPER_EMPLOYEE_UPDATE_REQUEST = 'DEVELOPER_EMPLOYEE_UPDATE_REQUEST'
export const DEVELOPER_EMPLOYEE_UPDATE_SUCCESS = 'DEVELOPER_EMPLOYEE_UPDATE_SUCCESS'
export const DEVELOPER_EMPLOYEE_UPDATE_FAIL = 'DEVELOPER_EMPLOYEE_UPDATE_FAIL'
export const DEVELOPER_EMPLOYEE_UPDATE_VALIDATION = 'DEVELOPER_EMPLOYEE_UPDATE_VALIDATION'

export const DEVELOPER_EMPLOYEE_DELETE_REQUEST = 'DEVELOPER_EMPLOYEE_DELETE_REQUEST'
export const DEVELOPER_EMPLOYEE_DELETE_SUCCESS = 'DEVELOPER_EMPLOYEE_DELETE_SUCCESS'
export const DEVELOPER_EMPLOYEE_DELETE_FAIL = 'DEVELOPER_EMPLOYEE_DELETE_FAIL'