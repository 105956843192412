import { CHILD_DELETE_FAIL, CHILD_DELETE_REQUEST, CHILD_DELETE_SUCCESS, CHILD_EDIT_FAIL, CHILD_EDIT_REQUEST, CHILD_EDIT_SUCCESS, CHILD_LIST_FAIL, CHILD_LIST_REQUEST, CHILD_LIST_SUCCESS, CHILD_SAVE_FAIL, CHILD_SAVE_REQUEST, CHILD_SAVE_SUCCESS, CHILD_SAVE_VALIDATION, CHILD_UPDATE_FAIL, CHILD_UPDATE_REQUEST, CHILD_UPDATE_SUCCESS, CHILD_UPDATE_VALIDATION, MENU_LIST_FAIL, MENU_LIST_REQUEST, MENU_LIST_SUCCESS, PARENT_ALL_LIST_FAIL, PARENT_ALL_LIST_REQUEST, PARENT_ALL_LIST_SUCCESS, PARENT_DELETE_FAIL, PARENT_DELETE_REQUEST, PARENT_DELETE_SUCCESS, PARENT_EDIT_FAIL, PARENT_EDIT_REQUEST, PARENT_EDIT_SUCCESS, PARENT_LIST_FAIL, PARENT_LIST_REQUEST, PARENT_LIST_SUCCESS, PARENT_SAVE_FAIL, PARENT_SAVE_REQUEST, PARENT_SAVE_SUCCESS, PARENT_SAVE_VALIDATION, PARENT_UPDATE_FAIL, PARENT_UPDATE_REQUEST, PARENT_UPDATE_SUCCESS, PARENT_UPDATE_VALIDATION, ROLE_ALL_LIST_FAIL, ROLE_ALL_LIST_REQUEST, ROLE_ALL_LIST_SUCCESS, ROLE_MYCRM_DELETE_FAIL, ROLE_MYCRM_DELETE_REQUEST, ROLE_MYCRM_DELETE_SUCCESS, ROLE_MYCRM_EDIT_FAIL, ROLE_MYCRM_EDIT_REQUEST, ROLE_MYCRM_EDIT_SUCCESS, ROLE_MYCRM_LIST_FAIL, ROLE_MYCRM_LIST_REQUEST, ROLE_MYCRM_LIST_SUCCESS, ROLE_MYCRM_SAVE_FAIL, ROLE_MYCRM_SAVE_REQUEST, ROLE_MYCRM_SAVE_SUCCESS, ROLE_MYCRM_SAVE_VALIDATION, ROLE_MYCRM_UPDATE_FAIL, ROLE_MYCRM_UPDATE_REQUEST, ROLE_MYCRM_UPDATE_SUCCESS, ROLE_MYCRM_UPDATE_VALIDATION, ROLE_MY_DELETE_FAIL, ROLE_MY_DELETE_REQUEST, ROLE_MY_DELETE_SUCCESS, ROLE_MY_EDIT_FAIL, ROLE_MY_EDIT_REQUEST, ROLE_MY_EDIT_SUCCESS, ROLE_MY_LIST_FAIL, ROLE_MY_LIST_REQUEST, ROLE_MY_LIST_SUCCESS, ROLE_MY_SAVE_FAIL, ROLE_MY_SAVE_REQUEST, ROLE_MY_SAVE_SUCCESS, ROLE_MY_SAVE_VALIDATION, ROLE_MY_UPDATE_FAIL, ROLE_MY_UPDATE_REQUEST, ROLE_MY_UPDATE_SUCCESS, ROLE_MY_UPDATE_VALIDATION } from '../constants/menuConstants'
import axios from 'axios'

//=================================  Parent  ============================================

export const parentList = (page, search) => async (dispatch) => {
  try {
    dispatch({
      type: PARENT_LIST_REQUEST,
    })

    const { data } = await axios.get(`parent?page=${page}&search=${search}`)

    dispatch({
      type: PARENT_LIST_SUCCESS,
      payload: data
    })
  } catch (e) {
    const err = e.response && e.response.data.message ? e.response.data.message : e.message;
    dispatch({
      type: PARENT_LIST_FAIL,
      payload: err          
    })
  }
}

export const parentAllList = () => async (dispatch) => {
  try {
    dispatch({
      type: PARENT_ALL_LIST_REQUEST,
    })

    const { data } = await axios.get(`parent/lists/all`)

    dispatch({
      type: PARENT_ALL_LIST_SUCCESS,
      payload: data
    })
  } catch (e) {
    const err = e.response && e.response.data.message ? e.response.data.message : e.message;
    dispatch({
      type: PARENT_ALL_LIST_FAIL,
      payload: err          
    })
  }
}

export const parentListByAccess = (access) => async (dispatch) => {
  try {
    dispatch({
      type: PARENT_LIST_REQUEST,
    })

    const { data } = await axios.get(`parent/by/access`, {
      params : {
        'access' : access
      }
    })

    dispatch({
      type: PARENT_LIST_SUCCESS,
      payload: data
    })
  } catch (e) {
    const err = e.response && e.response.data.message ? e.response.data.message : e.message;
    dispatch({
      type: PARENT_LIST_FAIL,
      payload: err          
    })
  }
}

export const parentSave = (name, icon, access, history) => async (dispatch) => {
  try {
    dispatch({
      type: PARENT_SAVE_REQUEST
    })

    const config = { 
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };

    const formData = new FormData()
    formData.append('name', name)
    formData.append('icon', icon)
    formData.append('access', access)

    await axios.post('parent',formData,config)

    dispatch({
      type: PARENT_SAVE_SUCCESS,
    })

    history.push('/parent-index')
    
  } catch (e) {
    if (e.response.status === 422) {
      dispatch({
        type: PARENT_SAVE_VALIDATION,
        payload: e.response.data.errors
      })
    } else {
      const err = e.response && e.response.data.message ? e.response.data.message : e.message;
      dispatch({
        type: PARENT_SAVE_FAIL,
        payload: err          
      })
    }
  }
}

export const parentEdit = (id) => async (dispatch) => {
  try {
    dispatch({
      type: PARENT_EDIT_REQUEST,
    })

    const { data } = await axios.get(`parent/${id}/edit`)

    dispatch({
      type: PARENT_EDIT_SUCCESS,
      payload: data
    })
  } catch (e) {
    const err = e.response && e.response.data.message ? e.response.data.message : e.message;
    dispatch({
      type: PARENT_EDIT_FAIL,
      payload: err          
    })
  }
}

export const parentUpdate = (name, icon, access, id, history) => async (dispatch) => {
  try {
    dispatch({
      type: PARENT_UPDATE_REQUEST
    })

    const config = { 
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };

    const formData = new FormData()
    formData.append('name', name)
    formData.append('icon', icon)
    formData.append('access', access)
    formData.append('_method', 'PUT')

    await axios.post(`parent/${id}`,formData,config)

    dispatch({
      type: PARENT_UPDATE_SUCCESS,
    })
    history.push('/parent-index')
    
  } catch (e) {
    if (e.response.status === 422) {
      dispatch({
        type: PARENT_UPDATE_VALIDATION,
        payload: e.response.data.errors
      })
    } else {
      const err = e.response && e.response.data.message ? e.response.data.message : e.message;
      dispatch({
        type: PARENT_UPDATE_FAIL,
        payload: err          
      })
    }
  }
}

export const parentDelete = (id) => async (dispatch) => {
  try {
    dispatch({
      type: PARENT_DELETE_REQUEST
    })

    await axios.delete(`parent/${id}`)

    dispatch({
      type: PARENT_DELETE_SUCCESS
    })
  } catch (e) {
    const err = e.response && e.response.data.message ? e.response.data.message : e.message;
    dispatch({
      type: PARENT_DELETE_FAIL,
      payload: err          
    })
  }
}

//=================================  Child  ============================================

export const childList = (page, search) => async (dispatch) => {
  try {
    dispatch({
      type: CHILD_LIST_REQUEST,
    })

    const { data } = await axios.get(`child?page=${page}&search=${search}`)

    dispatch({
      type: CHILD_LIST_SUCCESS,
      payload: data
    })
  } catch (e) {
    const err = e.response && e.response.data.message ? e.response.data.message : e.message;
    dispatch({
      type: CHILD_LIST_FAIL,
      payload: err          
    })
  }
}

export const childSave = (name, parent, access, history) => async (dispatch) => {
  try {
    dispatch({
      type: CHILD_SAVE_REQUEST
    })

    const config = { 
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };

    const formData = new FormData()
    formData.append('name', name)
    formData.append('parent', parent)
    formData.append('access', access)

    await axios.post('child',formData,config)

    dispatch({
      type: CHILD_SAVE_SUCCESS,
    })

    history.push('/child-index')
    
  } catch (e) {
    if (e.response.status === 422) {
      dispatch({
        type: CHILD_SAVE_VALIDATION,
        payload: e.response.data.errors
      })
    } else {
      const err = e.response && e.response.data.message ? e.response.data.message : e.message;
      dispatch({
        type: CHILD_SAVE_FAIL,
        payload: err          
      })
    }
  }
}

export const childEdit = (id) => async (dispatch) => {
  try {
    dispatch({
      type: CHILD_EDIT_REQUEST,
    })

    const { data } = await axios.get(`child/${id}/edit`)

    dispatch({
      type: CHILD_EDIT_SUCCESS,
      payload: data
    })
  } catch (e) {
    const err = e.response && e.response.data.message ? e.response.data.message : e.message;
    dispatch({
      type: CHILD_EDIT_FAIL,
      payload: err          
    })
  }
}

export const childUpdate = (name, parent, access, id, history) => async (dispatch) => {
  try {
    dispatch({
      type: CHILD_UPDATE_REQUEST
    })

    const config = { 
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };

    const formData = new FormData()
    formData.append('name', name)
    formData.append('parent', parent)
    formData.append('access', access)
    formData.append('_method', 'PUT')

    await axios.post(`child/${id}`,formData,config)

    dispatch({
      type: CHILD_UPDATE_SUCCESS,
    })
    history.push('/child-index')
    
  } catch (e) {
    if (e.response.status === 422) {
      dispatch({
        type: CHILD_UPDATE_VALIDATION,
        payload: e.response.data.errors
      })
    } else {
      const err = e.response && e.response.data.message ? e.response.data.message : e.message;
      dispatch({
        type: CHILD_UPDATE_FAIL,
        payload: err          
      })
    }
  }
}

export const childDelete = (id) => async (dispatch) => {
  try {
    dispatch({
      type: CHILD_DELETE_REQUEST
    })

    await axios.delete(`child/${id}`)

    dispatch({
      type: CHILD_DELETE_SUCCESS
    })
  } catch (e) {
    const err = e.response && e.response.data.message ? e.response.data.message : e.message;
    dispatch({
      type: CHILD_DELETE_FAIL,
      payload: err          
    })
  }
}

//=================================  Role MY  ============================================

export const roleMYList = (guard_name, page, search) => async (dispatch) => {
  try {
    dispatch({
      type: ROLE_MY_LIST_REQUEST,
    })

    const { data } = await axios.get('role', {
      params: {
        'guard_name': guard_name,
        'page' : page,
        'search': search
      }
    })

    dispatch({
      type: ROLE_MY_LIST_SUCCESS,
      payload: data
    })
  } catch (e) {
    const err = e.response && e.response.data.message ? e.response.data.message : e.message;
    dispatch({
      type: ROLE_MY_LIST_FAIL,
      payload: err          
    })
  }
}

export const roleMYSave = (name, guard_name, permission, history) => async (dispatch) => {
  try {
    dispatch({
      type: ROLE_MY_SAVE_REQUEST
    })

    const config = { 
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };

    const formData = new FormData()
    formData.append('name', name)
    for (let i = 0; i < permission.length; i++) {
      formData.append('permission[]', permission[i])
    }
    formData.append('guard_name', guard_name)

    await axios.post('role',formData,config)

    dispatch({
      type: ROLE_MY_SAVE_SUCCESS,
    })

    if (guard_name === 'MY') {
      history.push('/role-my-index')
    }else{
      history.push('/role-mycrm-index')
    }
    
  } catch (e) {
    if (e.response.status === 422) {
      dispatch({
        type: ROLE_MY_SAVE_VALIDATION,
        payload: e.response.data.errors
      })
    } else {
      const err = e.response && e.response.data.message ? e.response.data.message : e.message;
      dispatch({
        type: ROLE_MY_SAVE_FAIL,
        payload: err          
      })
    }
  }
}

export const roleMYEdit = (id) => async (dispatch) => {
  try {
    dispatch({
      type: ROLE_MY_EDIT_REQUEST,
    })

    const { data } = await axios.get(`role/${id}/edit`)

    dispatch({
      type: ROLE_MY_EDIT_SUCCESS,
      payload: data
    })
  } catch (e) {
    const err = e.response && e.response.data.message ? e.response.data.message : e.message;
    dispatch({
      type: ROLE_MY_EDIT_FAIL,
      payload: err          
    })
  }
}

export const roleMYUpdate = (name, guard_name, permission, id, history) => async (dispatch) => {
  try {
    dispatch({
      type: ROLE_MY_UPDATE_REQUEST
    })

    const config = { 
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };

    const formData = new FormData()
    formData.append('name', name)
    formData.append('guard_name', guard_name)
    for (let i = 0; i < permission.length; i++) {
      formData.append('permission[]', permission[i])
    }
    formData.append('_method', 'PUT')

    await axios.post(`role/${id}`,formData,config)

    dispatch({
      type: ROLE_MY_UPDATE_SUCCESS,
    })

    if (guard_name === 'MY') {
      history.push('/role-my-index')
    }else{
      history.push('/role-mycrm-index')
    }
    
  } catch (e) {
    if (e.response.status === 422) {
      dispatch({
        type: ROLE_MY_UPDATE_VALIDATION,
        payload: e.response.data.errors
      })
    } else {
      const err = e.response && e.response.data.message ? e.response.data.message : e.message;
      dispatch({
        type: ROLE_MY_UPDATE_FAIL,
        payload: err          
      })
    }
  }
}

export const roleMYDelete = (id) => async (dispatch) => {
  try {
    dispatch({
      type: ROLE_MY_DELETE_REQUEST
    })

    await axios.delete(`role/${id}`)

    dispatch({
      type: ROLE_MY_DELETE_SUCCESS
    })
  } catch (e) {
    const err = e.response && e.response.data.message ? e.response.data.message : e.message;
    dispatch({
      type: ROLE_MY_DELETE_FAIL,
      payload: err          
    })
  }
}

//=================================  Role MYCRM  ============================================

export const roleMYCRMList = (guard_name, page, search) => async (dispatch) => {
  try {
    dispatch({
      type: ROLE_MYCRM_LIST_REQUEST,
    })

    const { data } = await axios.get('role', {
      params: {
        'guard_name': guard_name,
        'page' : page,
        'search': search
      }
    })

    dispatch({
      type: ROLE_MYCRM_LIST_SUCCESS,
      payload: data
    })
  } catch (e) {
    const err = e.response && e.response.data.message ? e.response.data.message : e.message;
    dispatch({
      type: ROLE_MYCRM_LIST_FAIL,
      payload: err          
    })
  }
}

export const roleMYCRMSave = (name, guard_name, permission, history) => async (dispatch) => {
  try {
    dispatch({
      type: ROLE_MYCRM_SAVE_REQUEST
    })

    const config = { 
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };

    const formData = new FormData()
    formData.append('name', name)
    for (let i = 0; i < permission.length; i++) {
      formData.append('permission[]', permission[i])
    }
    formData.append('guard_name', guard_name)

    await axios.post('role',formData,config)

    dispatch({
      type: ROLE_MYCRM_SAVE_SUCCESS,
    })

    if (guard_name === 'MY') {
      history.push('/role-my-index')
    }else{
      history.push('/role-mycrm-index')
    }
    
  } catch (e) {
    if (e.response.status === 422) {
      dispatch({
        type: ROLE_MYCRM_SAVE_VALIDATION,
        payload: e.response.data.errors
      })
    } else {
      const err = e.response && e.response.data.message ? e.response.data.message : e.message;
      dispatch({
        type: ROLE_MYCRM_SAVE_FAIL,
        payload: err          
      })
    }
  }
}

export const roleMYCRMEdit = (id) => async (dispatch) => {
  try {
    dispatch({
      type: ROLE_MYCRM_EDIT_REQUEST,
    })

    const { data } = await axios.get(`role/${id}/edit`)

    dispatch({
      type: ROLE_MYCRM_EDIT_SUCCESS,
      payload: data
    })
  } catch (e) {
    const err = e.response && e.response.data.message ? e.response.data.message : e.message;
    dispatch({
      type: ROLE_MYCRM_EDIT_FAIL,
      payload: err          
    })
  }
}

export const roleMYCRMUpdate = (name, guard_name, permission, id, history) => async (dispatch) => {
  try {
    dispatch({
      type: ROLE_MYCRM_UPDATE_REQUEST
    })

    const config = { 
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };

    const formData = new FormData()
    formData.append('name', name)
    formData.append('guard_name', guard_name)
    for (let i = 0; i < permission.length; i++) {
      formData.append('permission[]', permission[i])
    }
    formData.append('_method', 'PUT')

    await axios.post(`role/${id}`,formData,config)

    dispatch({
      type: ROLE_MYCRM_UPDATE_SUCCESS,
    })

    if (guard_name === 'MY') {
      history.push('/role-my-index')
    }else{
      history.push('/role-mycrm-index')
    }
    
  } catch (e) {
    if (e.response.status === 422) {
      dispatch({
        type: ROLE_MYCRM_UPDATE_VALIDATION,
        payload: e.response.data.errors
      })
    } else {
      const err = e.response && e.response.data.message ? e.response.data.message : e.message;
      dispatch({
        type: ROLE_MYCRM_UPDATE_FAIL,
        payload: err          
      })
    }
  }
}

export const roleMYCRMDelete = (id) => async (dispatch) => {
  try {
    dispatch({
      type: ROLE_MYCRM_DELETE_REQUEST
    })

    await axios.delete(`role/${id}`)

    dispatch({
      type: ROLE_MYCRM_DELETE_SUCCESS
    })
  } catch (e) {
    const err = e.response && e.response.data.message ? e.response.data.message : e.message;
    dispatch({
      type: ROLE_MYCRM_DELETE_FAIL,
      payload: err          
    })
  }
}

//=================================  Menu  ============================================

export const menuList = (access) => async (dispatch) => {
  try {
    dispatch({
      type: MENU_LIST_REQUEST,
    })

    const { data } = await axios.get('menu', {
      params: {
        'access' : access
      }
    })

    dispatch({
      type: MENU_LIST_SUCCESS,
      payload: data
    })
  } catch (e) {
    const err = e.response && e.response.data.message ? e.response.data.message : e.message;
    dispatch({
      type: MENU_LIST_FAIL,
      payload: err          
    })
  }
}

//=================================  Role  ============================================

export const roleAllList = (guard_name) => async (dispatch) => {
  try {
    dispatch({
      type: ROLE_ALL_LIST_REQUEST,
    })

    const { data } = await axios.get('role/lists/my', {
      params: {
        'guard_name' : guard_name
      }
    })

    dispatch({
      type: ROLE_ALL_LIST_SUCCESS,
      payload: data
    })
  } catch (e) {
    const err = e.response && e.response.data.message ? e.response.data.message : e.message;
    dispatch({
      type: ROLE_ALL_LIST_FAIL,
      payload: err          
    })
  }
}