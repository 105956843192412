import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Profile } from '../../store/actions/authActions'

const Dashboard = () => {
  let dispatch = useDispatch()

  useEffect(() => {
    dispatch(Profile())
  }, [dispatch])

  return (
    <div className="">
      <div className="page-title">
        <div className="title_left">
          <h3>The new my.loanmarket.co.id is here !</h3>
        </div>
      </div>
      <div className="clearfix"></div><br/>
      <div className="row">
        <div className="col-md-12 col-sm-12 col-xs-12">
          <img src="images/lm-main-bg.jpg" alt="..." className="img-fluid" />
          <div className="" style={{ marginTop: 10 }}>
            <div className="x_content">
              <div className="row">
                <div className="col-lg-4 col-md-4 col-sm-6  ">
                  <div class="info-box">
                    <Link to={'office-index'}>
                      <span class="info-box-icon bg-lm" style={{ color: '#ffffff',backgroundColor: '#00a8ea' }}>
                        <i class="fa fa-building"></i>
                      </span>
                    </Link>
                    <div class="info-box-content">
                        <span class="info-box-number">Offices</span>
                        <p>Mengelola database seluruh kantor. Menambah, mengubah dan menghapus</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6  ">
                  <div class="info-box">
                    <Link to={'role-mycrm-index'}>
                      <span class="info-box-icon bg-lm" style={{ color: '#ffffff',backgroundColor: '#00a8ea' }}>
                        <i class="fa fa-lock"></i>
                      </span>
                    </Link>
                    <div class="info-box-content">
                        <span class="info-box-number">Role MYCRM</span>
                        <p>Mengelola akses user di MYCRM Loan Market</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6  ">
                  <div class="info-box">
                    <Link to={'role-my-index'}>
                      <span class="info-box-icon bg-lm" style={{ color: '#ffffff',backgroundColor: '#00a8ea' }}>
                        <i class="fa fa-lock"></i>
                      </span>
                    </Link>
                    <div class="info-box-content">
                        <span class="info-box-number">Role MY</span>
                        <p>Mengelola akses user di MY Loan Market</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            </div>
        </div>
      </div>
    </div>
  )
}

export default Dashboard
