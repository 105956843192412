import React, { useState, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { roleMYCRMUpdate, roleMYCRMEdit, menuList } from '../../../../store/actions/menuActions'
import { Checkbox, Divider  } from 'antd'

const RoleMYCRMEdit = () => {
  const [name, setname] = useState('')
  const [permission, setpermission] = useState([])
  console.log(name);
  console.log(permission);

  const dispatch = useDispatch()
  const history = useHistory()
  const id = localStorage.getItem('id_role_mycrm');

  useEffect(() => {
    dispatch(menuList('MYCRM'))
      dispatch(roleMYCRMEdit(id))
  }, [dispatch, id])

  const allMenuList = useSelector(state => state.menuList)
  const { menu } = allMenuList
  
  const dataroleEdit = useSelector(state => state.roleMYCRMEdit)
  const { role } = dataroleEdit

  useMemo(() => {
    if (role) {
      setname(role.name)
      setpermission(role.permissions_name)
    }else{
      setpermission([])
    }
  }, [role])

  const onChangeCheked = (e, value) => {
    console.log(e.target.checked);
    if (e.target.checked) {
      setpermission(permission => permission.concat(value));
    }else{
      let filter = permission.filter((e) => e !== value );
      setpermission(filter);
    }
  }

  const dataroleUpdate = useSelector(state => state.roleMYCRMUpdate)
  const { validation } = dataroleUpdate

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(roleMYCRMUpdate(name, 'MYCRM', permission, id, history))
  }

  return (
    <div className="">
      <div className="page-title">
        <div className="title_left">
          <h3>Edit Role MYCRM</h3>
        </div>
      </div>
      <div className="clearfix"></div><br/>
      <div className="row">
        <div className="col-md-12 col-sm-12 col-xs-12">
          <div className="x_panel">
            <div className="x_title">
              <h2>Form Input</h2>
              <div className="clearfix"></div>
            </div>
            
            <div className="x_content">  
              <form onSubmit={(e) => handleSubmit(e)}>
                <div className="row">
                  <div className="col-md-6 col-sm-12 form-group">
                    <label>Name</label>
                    <input type="text" 
                      className={ validation.length !== 0 && validation['name'] ? 'form-control parsley-error' : 'form-control' }
                      name="name" 
                      required 
                      onChange={(e) => setname(e.target.value)}
                      value={name}
                    />
                    
                    {
                      validation.length !== 0 
                      ?
                        <ul className="parsley-errors-list filled">
                          <li className="parsley-required">{validation['name']}</li>
                        </ul>
                      :
                        null
                    }
                  </div>
                </div>
                <br/>
                {
                  menu.map((parent) => {
                    return(
                      <div key={parent.id}>
                        <div className="row">
                          <div className="col-md-12 col-sm-12 form-group">
                            <h2 id="glyphicons-glyphs">
                              <span style={{ marginRight:'20px' }}>{parent.name}</span>
                            </h2>
                          </div>
                          <div className="col-md-12 col-sm-12 form-group">
                            {
                              parent.permission.map((parentpermis) => {
                                if (permission.find(e => e === parentpermis)) {
                                  return(
                                    <Checkbox checked={true} key={parentpermis.toString()} onChange={(e) => onChangeCheked(e,parentpermis)}>{parentpermis}</Checkbox>
                                  )
                                }else{
                                  return(
                                    <Checkbox key={parentpermis.toString()} onChange={(e) => onChangeCheked(e,parentpermis)}>{parentpermis}</Checkbox>
                                  )
                                }
                              })
                            }
                            {
                              parent.child.map((child) => {
                                return(
                                  <div key={child.id} style={{ marginLeft: '25px', marginTop: '20px' }}>
                                    <div className="row">
                                      <div className="col-md-12 col-sm-12 form-group">
                                        <h2 id="glyphicons-glyphs">
                                          <span style={{ marginRight:'20px' }}>{child.name}</span>
                                        </h2>
                                      </div>
                                      <div className="col-md-12 col-sm-12 form-group">
                                      {
                                        child.permission.map((childpermis) => {
                                          if (permission.find(e => e === childpermis)) {
                                            return(
                                              <Checkbox checked={true} key={childpermis.toString()} onChange={(e) => onChangeCheked(e,childpermis)}>{childpermis}</Checkbox>
                                            )
                                          }else{
                                            return(
                                              <Checkbox key={childpermis.toString()} onChange={(e) => onChangeCheked(e,childpermis)}>{childpermis}</Checkbox>
                                            )
                                          }
                                        })
                                      }
                                      </div>
                                    </div>  
                                  </div>
                                )
                              })
                            }
                          </div>
                        </div>
                        <Divider/>
                      </div>
                    )
                  })
                }
                <br/>
                <button type="submit" className="btn btn-primary pull-right">Submit</button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default RoleMYCRMEdit
