//=================================  Parent  ============================================

export const PARENT_LIST_REQUEST = 'PARENT_LIST_REQUEST'
export const PARENT_LIST_SUCCESS = 'PARENT_LIST_SUCCESS'
export const PARENT_LIST_FAIL = 'PARENT_LIST_FAIL'

export const PARENT_ALL_LIST_REQUEST = 'PARENT_ALL_LIST_REQUEST'
export const PARENT_ALL_LIST_SUCCESS = 'PARENT_ALL_LIST_SUCCESS'
export const PARENT_ALL_LIST_FAIL = 'PARENT_ALL_LIST_FAIL'

export const PARENT_SAVE_REQUEST = 'PARENT_SAVE_REQUEST'
export const PARENT_SAVE_SUCCESS = 'PARENT_SAVE_SUCCESS'
export const PARENT_SAVE_FAIL = 'PARENT_SAVE_FAIL'
export const PARENT_SAVE_VALIDATION = 'PARENT_SAVE_VALIDATION'

export const PARENT_EDIT_REQUEST = 'PARENT_EDIT_REQUEST'
export const PARENT_EDIT_SUCCESS = 'PARENT_EDIT_SUCCESS'
export const PARENT_EDIT_FAIL = 'PARENT_EDIT_FAIL'

export const PARENT_UPDATE_REQUEST = 'PARENT_UPDATE_REQUEST'
export const PARENT_UPDATE_SUCCESS = 'PARENT_UPDATE_SUCCESS'
export const PARENT_UPDATE_FAIL = 'PARENT_UPDATE_FAIL'
export const PARENT_UPDATE_VALIDATION = 'PARENT_UPDATE_VALIDATION'

export const PARENT_DELETE_REQUEST = 'PARENT_DELETE_REQUEST'
export const PARENT_DELETE_SUCCESS = 'PARENT_DELETE_SUCCESS'
export const PARENT_DELETE_FAIL = 'PARENT_DELETE_FAIL'

//=================================  Child  ============================================

export const CHILD_LIST_REQUEST = 'CHILD_LIST_REQUEST'
export const CHILD_LIST_SUCCESS = 'CHILD_LIST_SUCCESS'
export const CHILD_LIST_FAIL = 'CHILD_LIST_FAIL'

export const CHILD_SAVE_REQUEST = 'CHILD_SAVE_REQUEST'
export const CHILD_SAVE_SUCCESS = 'CHILD_SAVE_SUCCESS'
export const CHILD_SAVE_FAIL = 'CHILD_SAVE_FAIL'
export const CHILD_SAVE_VALIDATION = 'CHILD_SAVE_VALIDATION'

export const CHILD_EDIT_REQUEST = 'CHILD_EDIT_REQUEST'
export const CHILD_EDIT_SUCCESS = 'CHILD_EDIT_SUCCESS'
export const CHILD_EDIT_FAIL = 'CHILD_EDIT_FAIL'

export const CHILD_UPDATE_REQUEST = 'CHILD_UPDATE_REQUEST'
export const CHILD_UPDATE_SUCCESS = 'CHILD_UPDATE_SUCCESS'
export const CHILD_UPDATE_FAIL = 'CHILD_UPDATE_FAIL'
export const CHILD_UPDATE_VALIDATION = 'CHILD_UPDATE_VALIDATION'

export const CHILD_DELETE_REQUEST = 'CHILD_DELETE_REQUEST'
export const CHILD_DELETE_SUCCESS = 'CHILD_DELETE_SUCCESS'
export const CHILD_DELETE_FAIL = 'CHILD_DELETE_FAIL'

//=================================  Role MY  ============================================

export const ROLE_MY_LIST_REQUEST = 'ROLE_MY_LIST_REQUEST'
export const ROLE_MY_LIST_SUCCESS = 'ROLE_MY_LIST_SUCCESS'
export const ROLE_MY_LIST_FAIL = 'ROLE_MY_LIST_FAIL'

export const ROLE_MY_SAVE_REQUEST = 'ROLE_MY_SAVE_REQUEST'
export const ROLE_MY_SAVE_SUCCESS = 'ROLE_MY_SAVE_SUCCESS'
export const ROLE_MY_SAVE_FAIL = 'ROLE_MY_SAVE_FAIL'
export const ROLE_MY_SAVE_VALIDATION = 'ROLE_MY_SAVE_VALIDATION'

export const ROLE_MY_EDIT_REQUEST = 'ROLE_MY_EDIT_REQUEST'
export const ROLE_MY_EDIT_SUCCESS = 'ROLE_MY_EDIT_SUCCESS'
export const ROLE_MY_EDIT_FAIL = 'ROLE_MY_EDIT_FAIL'

export const ROLE_MY_UPDATE_REQUEST = 'ROLE_MY_UPDATE_REQUEST'
export const ROLE_MY_UPDATE_SUCCESS = 'ROLE_MY_UPDATE_SUCCESS'
export const ROLE_MY_UPDATE_FAIL = 'ROLE_MY_UPDATE_FAIL'
export const ROLE_MY_UPDATE_VALIDATION = 'ROLE_MY_UPDATE_VALIDATION'

export const ROLE_MY_DELETE_REQUEST = 'ROLE_MY_DELETE_REQUEST'
export const ROLE_MY_DELETE_SUCCESS = 'ROLE_MY_DELETE_SUCCESS'
export const ROLE_MY_DELETE_FAIL = 'ROLE_MY_DELETE_FAIL'

//=================================  Role MYCRM  ============================================

export const ROLE_MYCRM_LIST_REQUEST = 'ROLE_MYCRM_LIST_REQUEST'
export const ROLE_MYCRM_LIST_SUCCESS = 'ROLE_MYCRM_LIST_SUCCESS'
export const ROLE_MYCRM_LIST_FAIL = 'ROLE_MYCRM_LIST_FAIL'

export const ROLE_MYCRM_SAVE_REQUEST = 'ROLE_MYCRM_SAVE_REQUEST'
export const ROLE_MYCRM_SAVE_SUCCESS = 'ROLE_MYCRM_SAVE_SUCCESS'
export const ROLE_MYCRM_SAVE_FAIL = 'ROLE_MYCRM_SAVE_FAIL'
export const ROLE_MYCRM_SAVE_VALIDATION = 'ROLE_MYCRM_SAVE_VALIDATION'

export const ROLE_MYCRM_EDIT_REQUEST = 'ROLE_MYCRM_EDIT_REQUEST'
export const ROLE_MYCRM_EDIT_SUCCESS = 'ROLE_MYCRM_EDIT_SUCCESS'
export const ROLE_MYCRM_EDIT_FAIL = 'ROLE_MYCRM_EDIT_FAIL'

export const ROLE_MYCRM_UPDATE_REQUEST = 'ROLE_MYCRM_UPDATE_REQUEST'
export const ROLE_MYCRM_UPDATE_SUCCESS = 'ROLE_MYCRM_UPDATE_SUCCESS'
export const ROLE_MYCRM_UPDATE_FAIL = 'ROLE_MYCRM_UPDATE_FAIL'
export const ROLE_MYCRM_UPDATE_VALIDATION = 'ROLE_MYCRM_UPDATE_VALIDATION'

export const ROLE_MYCRM_DELETE_REQUEST = 'ROLE_MYCRM_DELETE_REQUEST'
export const ROLE_MYCRM_DELETE_SUCCESS = 'ROLE_MYCRM_DELETE_SUCCESS'
export const ROLE_MYCRM_DELETE_FAIL = 'ROLE_MYCRM_DELETE_FAIL'

//=================================  Menu  ============================================

export const MENU_LIST_REQUEST = 'MENU_LIST_REQUEST'
export const MENU_LIST_SUCCESS = 'MENU_LIST_SUCCESS'
export const MENU_LIST_FAIL = 'MENU_LIST_FAIL'

//=================================  Role  ============================================

export const ROLE_ALL_LIST_REQUEST = 'ROLE_ALL_LIST_REQUEST'
export const ROLE_ALL_LIST_SUCCESS = 'ROLE_ALL_LIST_SUCCESS'
export const ROLE_ALL_LIST_FAIL = 'ROLE_ALL_LIST_FAIL'