import { DEVELOPER_EMPLOYEE_DELETE_FAIL, DEVELOPER_EMPLOYEE_DELETE_REQUEST, DEVELOPER_EMPLOYEE_DELETE_SUCCESS, DEVELOPER_EMPLOYEE_EDIT_FAIL, DEVELOPER_EMPLOYEE_EDIT_REQUEST, DEVELOPER_EMPLOYEE_EDIT_SUCCESS, DEVELOPER_EMPLOYEE_LIST_FAIL, DEVELOPER_EMPLOYEE_LIST_REQUEST, DEVELOPER_EMPLOYEE_LIST_SUCCESS, DEVELOPER_EMPLOYEE_SAVE_FAIL, DEVELOPER_EMPLOYEE_SAVE_REQUEST, DEVELOPER_EMPLOYEE_SAVE_SUCCESS, DEVELOPER_EMPLOYEE_SAVE_VALIDATION, DEVELOPER_EMPLOYEE_UPDATE_FAIL, DEVELOPER_EMPLOYEE_UPDATE_REQUEST, DEVELOPER_EMPLOYEE_UPDATE_SUCCESS, DEVELOPER_EMPLOYEE_UPDATE_VALIDATION } from '../constants/developeremployeeConstants'
import { message } from 'antd'

const initialState = {
  validation: [],
}

const key = 'updatable';

//=================================  Developer employee  ============================================

export const developeremployeeListReducer = (state = {developeremployee:[], listsDeveloperemployee:[]}, action) => {
  switch (action.type) {
    case DEVELOPER_EMPLOYEE_LIST_REQUEST:
      return{ ...state, loading: true, error: [] }
    case DEVELOPER_EMPLOYEE_LIST_SUCCESS:
      return{ ...state, loading: false, developeremployee: action.payload.data, listsDeveloperemployee: action.payload.data.data }
    case DEVELOPER_EMPLOYEE_LIST_FAIL:
      return{ ...state, loading: false, error: action.payload }
    default:
      return state
  }
}

export const developeremployeeSaveReducer = (state = initialState, action) => {
  switch (action.type) {
    case DEVELOPER_EMPLOYEE_SAVE_REQUEST:
      message.loading('Loading...', key);
      return{ ...state, loading: true, error: [], validation: [] }
    case DEVELOPER_EMPLOYEE_SAVE_SUCCESS:
      message.success({ content: 'Insert success', key });
      return{ ...state, loading: false }
    case DEVELOPER_EMPLOYEE_SAVE_VALIDATION:
      message.error({ content: 'Please fill all required field', key });
      return{ ...state, loading: false, validation: action.payload }
    case DEVELOPER_EMPLOYEE_SAVE_FAIL:
      message.error({ content: action.payload, key });
      return{ ...state, loading: false, error: action.payload }
    default:
      return state
  }
}

export const developeremployeeEditReducer = (state = {developeremployee:{}}, action) => {
  switch (action.type) {
    case DEVELOPER_EMPLOYEE_EDIT_REQUEST:
      return{ ...state, loading: true, error: [] }
    case DEVELOPER_EMPLOYEE_EDIT_SUCCESS:
      return{ ...state, loading: false, developeremployee: action.payload.data }
    case DEVELOPER_EMPLOYEE_EDIT_FAIL:
      return{ ...state, loading: false, error: action.payload }
    default:
      return state
  }
}

export const developeremployeeUpdateReducer = (state = initialState, action) => {
  switch (action.type) {
    case DEVELOPER_EMPLOYEE_UPDATE_REQUEST:
      message.loading('Loading...', key);
      return{ ...state, loading: true, error: [], validation: [] }
    case DEVELOPER_EMPLOYEE_UPDATE_SUCCESS:
      message.success({ content: 'Update success', key });
      return{ ...state, loading: false }
    case DEVELOPER_EMPLOYEE_UPDATE_VALIDATION:
      message.error({ content: 'Please fill all required field', key });
      return{ ...state, loading: false, validation: action.payload }
    case DEVELOPER_EMPLOYEE_UPDATE_FAIL:
      message.error({ content: action.payload, key });
      return{ ...state, loading: false, error: action.payload }
    default:
      return state
  }
}

export const developeremployeeDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case DEVELOPER_EMPLOYEE_DELETE_REQUEST:
      message.loading('Loading...', key);
      return{ ...state, loading: true, error: [] }
    case DEVELOPER_EMPLOYEE_DELETE_SUCCESS:
      message.success({ content: 'Delete success', key });
      return{ ...state, loading: false }
    case DEVELOPER_EMPLOYEE_DELETE_FAIL:
      message.error({ content: action.payload, key });
      return{ ...state, loading: false, error: action.payload }
    default:
      return state
  }
}

