import React, { useState, useEffect, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { principalEdit, principalUpdate, officeAllList, adviserAllList } from '../../../../store/actions/memberActions'
import { Switch, Select } from 'antd';

const PrincipalEdit = () => {
  const [name, setname] = useState('')
  const [email, setemail] = useState('')
  const [office, setoffice] = useState('')
  const [phone, setphone] = useState('')
  const [gender, setgender] = useState('')
  const [joindate, setjoindate] = useState('')
  const [status, setstatus] = useState('')
  const [isnpwp, setisnpwp] = useState('')
  const [issalary, setissalary] = useState('')
  const [haired, sethaired] = useState('')
  console.log(haired)

  let dispatch = useDispatch()
  let history = useHistory()
  const id = localStorage.getItem('id_principal');

  useEffect(() => {
    dispatch(principalEdit(id))
    dispatch(officeAllList())
    dispatch(adviserAllList())
  }, [dispatch, id])

  const dataofficeAllList = useSelector(state => state.officeAllList)
  const listsOffice = dataofficeAllList.listsOffice.map((item) => {return {value: item.value, label: item.label}})
  
  const dataadviserAllList = useSelector(state => state.adviserAllList)
  const listsAllAdviser = dataadviserAllList.listsAllAdviser.map((item) => {return {value: item.id, label: item.name}});

  const dataprincipalUpdate = useSelector(state => state.principalUpdate)
  const { validation } = dataprincipalUpdate

  const dataLogin = useSelector(state => state.login)
  const { userInfo } = dataLogin
  const updated_by = userInfo.user.id

  const dataprincipalEdit = useSelector(state => state.principalEdit)
  const { principal } = dataprincipalEdit

  useMemo(() => {
    if (principal) {
      setname(principal.name)
      setemail(principal.email)
      setoffice(principal.id_office)
      setphone(principal.phone)
      setgender(principal.gender)
      setjoindate(principal.join_date)
      setstatus(principal.active)
      setisnpwp(principal.is_npwp)
      setissalary(principal.is_salary)
      sethaired(principal.haired_by)
    }
  }, [principal])

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(principalUpdate(name, email, office, phone, gender, joindate, status, isnpwp, issalary, haired, updated_by, id, history))
  }


  return (
    <div className="">
      <div className="page-title">
        <div className="title_left">
          <h3>Edit Principal</h3>
        </div>
      </div>
      <div className="clearfix"></div><br/>
      <div className="row">
        <div className="col-md-6 col-sm-6 col-xs-12">
          <div className="x_panel">
            <div className="x_title">
              <h2>Form Input</h2>
              <div className="clearfix"></div>
            </div>
            
            <div className="x_content">  
              <form onSubmit={(e) => handleSubmit(e)}>
                <div className="row">
                  <div className="col-md-6 col-sm-6 col-xs-12">
                    <div className="form-group">
                      <label for="name">Name</label>
                      <input type="text" 
                        className={ validation.length !== 0 && validation['name'] ? 'form-control parsley-error' : 'form-control' }
                        required 
                        onChange={(e) => setname(e.target.value)}
                        value={name}
                      />
                      {
                        validation.length !== 0 
                        ?
                          <ul className="parsley-errors-list filled">
                            <li className="parsley-required">{validation['name']}</li>
                          </ul>
                        :
                          null
                      }
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-6 col-xs-12">
                    <div className="form-group">
                      <label for="name">Email</label>
                      <input type="email" 
                        className={ validation.length !== 0 && validation['email'] ? 'form-control parsley-error' : 'form-control' }
                        required 
                        onChange={(e) => setemail(e.target.value)}
                        value={email}
                      />
                      {
                        validation.length !== 0 
                        ?
                          <ul className="parsley-errors-list filled">
                            <li className="parsley-required">{validation['email']}</li>
                          </ul>
                        :
                          null
                      }
                    </div>
                  </div>
                </div>
                
                <div className="form-group d-flex flex-column">
                  <label for="name">Office</label>
                  <Select
                    showSearch
                    placeholder="Select an office"
                    optionFilterProp="children"
                    onChange={(value) => setoffice(value)}
                    filterOption={(input, option) =>
                      (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                    }
                    options={listsOffice}
                    value={office}
                  />
                  {
                        validation.length !== 0 
                        ?
                          <ul className="parsley-errors-list filled">
                            <li className="parsley-required">{validation['office']}</li>
                          </ul>
                        :
                          null
                      }
                </div>
                
                <div className="row">
                  <div className="col-md-6 col-sm-6 col-xs-12">
                    <div className="form-group">
                      <label for="name">Phone</label>
                      <input type="text" 
                        className={ validation.length !== 0 && validation['phone'] ? 'form-control parsley-error' : 'form-control' }
                        required 
                        onChange={(e) => setphone(e.target.value)}
                        value={phone}
                      />
                      {
                        validation.length !== 0 
                        ?
                          <ul className="parsley-errors-list filled">
                            <li className="parsley-required">{validation['phone']}</li>
                          </ul>
                        :
                          null
                      }
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-6 col-xs-12">
                    <div className="form-group">
                      <label for="name">Gender</label>
                      <select 
                        className={ validation.length !== 0 && validation['gender'] ? 'form-control parsley-error' : 'form-control' }
                        required
                        onChange={(e) => setgender(e.target.value)}
                        defaultValue={gender}
                        value={gender}
                      >
                        <option disabled selected value="">Select Gender</option>
                        <option value="female">Female</option>
                        <option value="male">Male</option>
                      </select>
                      {
                        validation.length !== 0 
                        ?
                          <ul className="parsley-errors-list filled">
                            <li className="parsley-required">{validation['gender']}</li>
                          </ul>
                        :
                          null
                      }
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6 col-sm-6 col-xs-12">
                    <div className="form-group">
                      <label for="name">Join Date</label>
                      <input type="date" 
                        className={ validation.length !== 0 && validation['join_date'] ? 'form-control parsley-error' : 'form-control' }
                        name="name" 
                        required 
                        onChange={(e) => setjoindate(e.target.value)}
                        value={joindate}
                      />
                      {
                        validation.length !== 0 
                        ?
                          <ul className="parsley-errors-list filled">
                            <li className="parsley-required">{validation['join_date']}</li>
                          </ul>
                        :
                          null
                      }
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-6 col-xs-12">
                    <div className="form-group">
                      <label for="name">Status</label>
                      <select 
                        className={ validation.length !== 0 && validation['status'] ? 'form-control parsley-error' : 'form-control' }
                        required
                        onChange={(e) => setstatus(e.target.value)}
                        defaultValue={status}
                        value={status}
                      >
                        <option disabled selected value="">Select Status</option>
                        <option value="1">Active</option>
                        <option value="0">Deactive</option>
                      </select>
                      {
                        validation.length !== 0 
                        ?
                          <ul className="parsley-errors-list filled">
                            <li className="parsley-required">{validation['status']}</li>
                          </ul>
                        :
                          null
                      }
                    </div>
                  </div>
                </div>

                <div className="form-group d-flex flex-column">
                  <label for="name">Haired By</label>
                  <Select
                    showSearch
                    placeholder="Hired by"
                    optionFilterProp="children"
                    onChange={(value) => sethaired(value)}
                    filterOption={(input, option) =>
                      (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                    }
                    options={listsAllAdviser}
                    value={haired}
                  />
                  {
                        validation.length !== 0 
                        ?
                          <ul className="parsley-errors-list filled">
                            <li className="parsley-required">{validation['haired']}</li>
                          </ul>
                        :
                          null
                      }
                </div>

                <div className="row">
                  <div className="col-md-2 col-sm-2 col-xs-2">
                    <div className="form-group">
                      <label for="name">NPWP</label><br/>
                      <Switch checked={(isnpwp === 1 ? true : false)} onChange={(e) => (e === true ? setisnpwp(1) : setisnpwp(0))} />
                      {/* {
                        isnpwp === 1 ?
                        <Switch defaultChecked onChange={(e) => (e === true ? setisnpwp(1) : setisnpwp(0))} />
                        :
                        <Switch onChange={(e) => (e === true ? setisnpwp(1) : setisnpwp(0))} />
                      } */}
                      {
                        validation.length !== 0 
                        ?
                          <ul className="parsley-errors-list filled">
                            <li className="parsley-required">{validation['npwp']}</li>
                          </ul>
                        :
                          null
                      }
                    </div>
                  </div>
                  <div className="col-md-2 col-sm-2 col-xs-2">
                    <div className="form-group">
                      <label for="name">Salary</label><br/>
                      
                      <Switch checked={(issalary === 1 ? true : false)} onChange={(e) => (e === true ? setissalary(1) : setissalary(0))} />
                      {/* {
                        issalary === 1 ?
                        <Switch compact defaultChecked onChange={(e) => (e === true ? setissalary(1) : setissalary(0))} />
                        :
                        <Switch compact onChange={(e) => (e === true ? setissalary(1) : setissalary(0))} />
                      } */}
                      {
                        validation.length !== 0 
                        ?
                          <ul className="parsley-errors-list filled">
                            <li className="parsley-required">{validation['salary']}</li>
                          </ul>
                        :
                          null
                      }
                    </div>
                  </div>
                </div>

                <button type="submit" className="btn btn-primary pull-right">Submit</button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PrincipalEdit
