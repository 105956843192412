import { message } from "antd";
import axios from "axios";

const ActionType = {
  GET_PROMO_CATEGORIES: 'GET_PROMO_CATEGORIES',
  GET_PROMO: 'GET_PROMO',
  GET_PROMO_CATEGORY_ASSET: 'GET_PROMO_CATEGORY_ASSET',
};

function getPromoCategoriesActionCreator(promo_categories) {
  return {
    type: ActionType.GET_PROMO_CATEGORIES,
    payload: {
      promo_categories,
    },
  };
}

function getPromoActionCreator(promo) {
  return {
    type: ActionType.GET_PROMO,
    payload: {
      promo,
    },
  };
}

function getAssetPromoCategoryActionCreator(asset_promo_category) {
  return {
    type: ActionType.GET_PROMO_CATEGORY_ASSET,
    payload: {
      asset_promo_category,
    },
  };
}

// Promo Categories Page
function asyncGetAllPromoCategories(page) {
  return async (dispatch) => {
    const response = await axios.get(`promo-category`, {
      params: {
        'page' : page,
      }
    });
    if (response.status === 200) {
      dispatch(getPromoCategoriesActionCreator(response.data.data));
    }
  }
}

function asyncAddPromoCategories(title, pageNumber) {
  return async (dispatch) => {
    try {
      const response = await axios.post(`promo-category`, {title});
      if (response.status === 200) {
        const response = await axios.get(`promo-category`, {
          params: {
            'page' : pageNumber,
          }
        });
        if (response.status === 200) {
          dispatch(getPromoCategoriesActionCreator(response.data.data));
          message.success('Data berhasil ditambahkan');
        }
      }
    } catch (error) {
      message.error(error.response.data.message);
    }
  }
}

function asyncEditPromoCategories(id, title, pageNumber) {
  return async (dispatch) => {
    try {
      const response = await axios.post(`promo-category/${id}`, {title, _method: 'PUT'});
      if (response.status === 200) {
        const response = await axios.get(`promo-category`, {
          params: {
            'page' : pageNumber,
          }
        });
        if (response.status === 200) {
          dispatch(getPromoCategoriesActionCreator(response.data.data));
          message.success('Data berhasil diedit');
        }
      }
    } catch (error) {
      message.error(error.response.data.message);
    }
  }
}

function asyncDeletePromoCategories(id, pageNumber) {
  return async (dispatch) => {
    try {
      const response = await axios.delete(`promo-category/${id}`);
      if (response.status === 200) {
        const response = await axios.get(`promo-category`, {
          params: {
            'page' : pageNumber,
          }
        });
        if (response.status === 200) {
          dispatch(getPromoCategoriesActionCreator(response.data.data));
          message.success('Data berhasil dihapus');
        }
      }
    } catch (error) {
      message.error(error.response.data.message);
    }
  }
}

// Promo Page
function asyncGetAllPromo(page) {
  return async (dispatch) => {
    const response = await axios.get(`promo`, {
      params: {
        'page' : page,
      }
    });
    if (response.status === 200) {
      dispatch(getPromoActionCreator(response.data.data));
    }
  }
}

function asyncGetAssetPromoCategory() {
  return async (dispatch) => {
    const response = await axios.get(`promo-asset/category`);
    if (response.status === 200) {
      dispatch(getAssetPromoCategoryActionCreator(response.data.data));
    }
  }
}

function asyncAddPromo(title, category, content, file, summary, promoStartDate, promoEndDate) {
  return async (dispatch) => {
    try {
      const formData = new FormData();
      formData.append('title', title);
      formData.append('category_id', category);
      formData.append('content', content);
      formData.append('cover', file);
      formData.append('summary', summary);
      formData.append('start_date', promoStartDate);
      formData.append('end_date', promoEndDate);

      const response = await axios.post(`promo`, formData);
      if (response.status === 200) {
        const response = await axios.get(`promo`, {
          params: {
            'page' : 1,
          }
        });
        if (response.status === 200) {
          dispatch(getPromoActionCreator(response.data.data));
          message.success('Data berhasil ditambahkan');
        }
      }
    } catch (error) {
      message.error(error.response.data.message);
    }
  }
}

function asyncDeletePromo(id) {
  return async (dispatch) => {
    try {
      const response = await axios.delete(`promo/${id}`);
      if (response.status === 200) {
        const response = await axios.get(`promo`, {
          params: {
            'page' : 1,
          }
        });
        if (response.status === 200) {
          dispatch(getPromoActionCreator(response.data.data));
          message.success('Data berhasil dihapus');
        }
      }
    } catch (error) {
      message.error(error.response.data.message);
    }
  }
}

function asyncEditPromo(id, title, category, content, file, summary, promoStartDate, promoEndDate) {
  return async (dispatch) => {
    try {
      const formData = new FormData();
      formData.append('title', title);
      formData.append('category_id', category);
      formData.append('content', content);
      if (file) {
        formData.append('cover', file);
      }
      formData.append('summary', summary);
      formData.append('start_date', promoStartDate);
      formData.append('end_date', promoEndDate);
      formData.append('_method', 'PATCH');

      const response = await axios.post(`promo/${id}`, formData, {headers: {
        'Content-Type': 'multipart/form-data'
      }});
      if (response.status === 200) {
        const response = await axios.get(`promo`, {
          params: {
            'page' : 1,
          }
        });
        if (response.status === 200) {
          dispatch(getPromoActionCreator(response.data.data));
          message.success('Data berhasil diupdate!');
        }
      }
    } catch (error) {
      message.error(error.response.data.message);
    }
  }
}

export {
  ActionType,
  getPromoCategoriesActionCreator,
  getPromoActionCreator,
  getAssetPromoCategoryActionCreator,

  asyncGetAllPromoCategories,
  asyncAddPromoCategories,
  asyncEditPromoCategories,
  asyncDeletePromoCategories,

  asyncGetAllPromo,
  asyncGetAssetPromoCategory,
  asyncAddPromo,
  asyncDeletePromo,
  asyncEditPromo,
}

