import { BANK_LIST_FAIL, BANK_LIST_REQUEST, BANK_LIST_SUCCESS, MITRA_DELETE_FAIL, MITRA_DELETE_REQUEST, MITRA_DELETE_SUCCESS, MITRA_EDIT_FAIL, MITRA_EDIT_REQUEST, MITRA_EDIT_SUCCESS, MITRA_LIST_FAIL, MITRA_LIST_REQUEST, MITRA_LIST_SUCCESS, MITRA_SAVE_FAIL, MITRA_SAVE_REQUEST, MITRA_SAVE_SUCCESS, MITRA_SAVE_VALIDATION, MITRA_UPDATE_FAIL, MITRA_UPDATE_REQUEST, MITRA_UPDATE_SUCCESS, MITRA_UPDATE_VALIDATION } from '../constants/mitraConstants'
import axios from 'axios'

export const bankList = () => async (dispatch) => {
  try {
    dispatch({
      type: BANK_LIST_REQUEST,
    })

    const { data } = await axios.get(`mitra/list-bank`)

    dispatch({
      type: BANK_LIST_SUCCESS,
      payload: data
    })
  } catch (e) {
    const err = e.response && e.response.data.message ? e.response.data.message : e.message;
    dispatch({
      type: BANK_LIST_FAIL,
      payload: err          
    })
  }
}

//=================================  Mitra  ============================================

export const mitraList = (page, search) => async (dispatch) => {
  try {
    dispatch({
      type: MITRA_LIST_REQUEST,
    })

    const { data } = await axios.get(`mitra`, {
      params: {
        'page' : page,
        'search': search
      }
    })

    dispatch({
      type: MITRA_LIST_SUCCESS,
      payload: data
    })
  } catch (e) {
    const err = e.response && e.response.data.message ? e.response.data.message : e.message;
    dispatch({
      type: MITRA_LIST_FAIL,
      payload: err          
    })
  }
}

export const mitraSave = (name, email, phone, joindate, status, bank, office, created_by, history) => async (dispatch) => {
  try {
    dispatch({
      type: MITRA_SAVE_REQUEST
    })

    const config = { 
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };

    const formData = new FormData()
    for (let i = 0; i < bank.length; i++) {
      formData.append('bank[]', bank[i])
    }
    for (let i = 0; i < office.length; i++) {
      formData.append('office[]', office[i])
    }
    formData.append('name', name)
    formData.append('email', email)
    formData.append('phone', phone)
    formData.append('join_date', joindate)
    formData.append('status', status)
    formData.append('created_by', created_by)

    await axios.post('mitra',formData,config)

    dispatch({
      type: MITRA_SAVE_SUCCESS,
    })

    history.push('/partners-index')
    
  } catch (e) {
    if (e.response.status === 422) {
      dispatch({
        type: MITRA_SAVE_VALIDATION,
        payload: e.response.data.errors
      })
    } else {
      const err = e.response && e.response.data.message ? e.response.data.message : e.message;
      dispatch({
        type: MITRA_SAVE_FAIL,
        payload: err          
      })
    }
  }
}

export const mitraEdit = (id) => async (dispatch) => {
  try {
    dispatch({
      type: MITRA_EDIT_REQUEST,
    })

    const { data } = await axios.get(`mitra/${id}/edit`)

    dispatch({
      type: MITRA_EDIT_SUCCESS,
      payload: data
    })
  } catch (e) {
    const err = e.response && e.response.data.message ? e.response.data.message : e.message;
    dispatch({
      type: MITRA_EDIT_FAIL,
      payload: err          
    })
  }
}

export const mitraUpdate = (name, email, phone, joindate, status, bank, office, updated_by, id, history) => async (dispatch) => {
  try {
    dispatch({
      type: MITRA_UPDATE_REQUEST
    })

    const config = { 
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };
    
    const formData = new FormData()
    for (let i = 0; i < bank.length; i++) {
      formData.append('bank[]', bank[i])
    }
    for (let i = 0; i < office.length; i++) {
      formData.append('office[]', office[i])
    }
    formData.append('name', name)
    formData.append('email', email)
    formData.append('phone', phone)
    formData.append('join_date', joindate)
    formData.append('status', status)
    formData.append('updated_by', updated_by)
    formData.append('_method', 'PUT')

    await axios.post(`mitra/${id}`,formData,config)

    dispatch({
      type: MITRA_UPDATE_SUCCESS,
    })
    history.push('/partners-index')
    
  } catch (e) {
    if (e.response.status === 422) {
      dispatch({
        type: MITRA_UPDATE_VALIDATION,
        payload: e.response.data.errors
      })
    } else {
      const err = e.response && e.response.data.message ? e.response.data.message : e.message;
      dispatch({
        type: MITRA_UPDATE_FAIL,
        payload: err          
      })
    }
  }
}

export const mitraDelete = (id) => async (dispatch) => {
  try {
    dispatch({
      type: MITRA_DELETE_REQUEST
    })

    await axios.delete(`mitra/${id}`)

    dispatch({
      type: MITRA_DELETE_SUCCESS
    })
  } catch (e) {
    const err = e.response && e.response.data.message ? e.response.data.message : e.message;
    dispatch({
      type: MITRA_DELETE_FAIL,
      payload: err          
    })
  }
}