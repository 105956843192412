import React, { useState, useEffect, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { adviserEdit, adviserUpdate, officeAllList, adviserAllList, adviserBlacklist } from '../../../../store/actions/memberActions'
import { Modal, Switch, Input, message, Select } from 'antd';
const { TextArea } = Input;

const AdviserEdit = () => {
  const [name, setname] = useState('')
  const [email, setemail] = useState('')
  const [office, setoffice] = useState('')
  const [phone, setphone] = useState('')
  const [gender, setgender] = useState('')
  const [joindate, setjoindate] = useState('')
  const [status, setstatus] = useState('')
  const [isnpwp, setisnpwp] = useState('')
  const [issalary, setissalary] = useState('')
  const [haired, sethaired] = useState('')
  const [nik, setNik] = useState('')

  const [showModalBlacklist, setShowModalBlacklist] = useState(false);
  const [blacklistReason, setBlacklistReason] = useState('');

  let dispatch = useDispatch()
  let history = useHistory()
  const id = localStorage.getItem('id_adviser');

  useEffect(() => {
    dispatch(adviserEdit(id))
    dispatch(officeAllList())
    dispatch(adviserAllList())
  }, [dispatch, id])

  const dataofficeAllList = useSelector(state => state.officeAllList)
  const listsOffice = dataofficeAllList.listsOffice.map((item) => {return {value: item.value, label: item.label}})
  
  const dataadviserAllList = useSelector(state => state.adviserAllList)
  const listsAllAdviser = dataadviserAllList.listsAllAdviser.map((item) => {return {value: item.id, label: item.name}});

  const dataadviserUpdate = useSelector(state => state.adviserUpdate)
  const { validation } = dataadviserUpdate

  const dataLogin = useSelector(state => state.login)
  const { userInfo } = dataLogin
  const updated_by = userInfo.user.id

  const dataadviserEdit = useSelector(state => state.adviserEdit)
  const { adviser } = dataadviserEdit

  useMemo(() => {
    if (adviser) {
      setname(adviser.name)
      setemail(adviser.email)
      setoffice(adviser.id_office)
      setphone(adviser.phone)
      setgender(adviser.gender)
      setjoindate(adviser.join_date)
      setstatus(adviser.active)
      setisnpwp(adviser.is_npwp)
      setissalary(adviser.is_salary)
      sethaired(adviser.haired_by)
      setNik(adviser.nik)
    }
  }, [adviser])

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(adviserUpdate(name, email, office, phone, gender, joindate, status, isnpwp, issalary, haired, updated_by, id, history, nik))
  }

  const handleBlacklist = async (e) => {
    e.preventDefault();
    const res = await adviserBlacklist(id, blacklistReason);
    if (res.status === 200) {
      message.success('Berhasil Blacklist Adviser!')
      history.push('/adviser-index')
    } else {
      message.error('Gagal Blacklist Adviser!')
    }
  }


  return (
    <div className="">
      <div className="page-title">
        <div className="title_left">
          <h3>Edit Adviser</h3>
        </div>
      </div>
      <div className="clearfix"></div><br/>
      <div className="row">
        <div className="col-md-6 col-sm-6 col-xs-12">
          <div className="x_panel">
            <div className="x_title">
              <h2>Form Input</h2>
              <div className="clearfix"></div>
            </div>
            
            <div className="x_content">  
              <form onSubmit={(e) => handleSubmit(e)}>
                <div className="row">
                  <div className="col-md-6 col-sm-6 col-xs-12">
                    <div className="form-group">
                      <label for="name">Name</label>
                      <input type="text" 
                        className={ validation.length !== 0 && validation['name'] ? 'form-control parsley-error' : 'form-control' }
                        required 
                        onChange={(e) => setname(e.target.value)}
                        value={name}
                      />
                      {
                        validation.length !== 0 
                        ?
                          <ul className="parsley-errors-list filled">
                            <li className="parsley-required">{validation['name']}</li>
                          </ul>
                        :
                          null
                      }
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-6 col-xs-12">
                    <div className="form-group">
                      <label for="name">Email</label>
                      <input type="email" 
                        className={ validation.length !== 0 && validation['email'] ? 'form-control parsley-error' : 'form-control' }
                        required 
                        onChange={(e) => setemail(e.target.value)}
                        value={email}
                      />
                      {
                        validation.length !== 0 
                        ?
                          <ul className="parsley-errors-list filled">
                            <li className="parsley-required">{validation['email']}</li>
                          </ul>
                        :
                          null
                      }
                    </div>
                  </div>
                </div>
                <div>
                  <div className="form-group">
                    <label for="nik">NIK</label>
                    <input type="text" 
                      className='form-control' 
                      onChange={(e) => setNik(e.target.value)}
                      defaultValue={nik}
                      value={nik}
                    />
                  </div>
                </div>
                <div className="form-group d-flex flex-column">
                  <label for="name">Office</label>
                  <Select
                    showSearch
                    placeholder="Select an office"
                    optionFilterProp="children"
                    onChange={(value) => setoffice(value)}
                    filterOption={(input, option) =>
                      (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                    }
                    options={listsOffice}
                    value={office}
                  />
                  {
                        validation.length !== 0 
                        ?
                          <ul className="parsley-errors-list filled">
                            <li className="parsley-required">{validation['office']}</li>
                          </ul>
                        :
                          null
                      }
                </div>
                
                <div className="row">
                  <div className="col-md-6 col-sm-6 col-xs-12">
                    <div className="form-group">
                      <label for="name">Phone</label>
                      <input type="text" 
                        className={ validation.length !== 0 && validation['phone'] ? 'form-control parsley-error' : 'form-control' }
                        required 
                        onChange={(e) => setphone(e.target.value)}
                        value={phone}
                      />
                      {
                        validation.length !== 0 
                        ?
                          <ul className="parsley-errors-list filled">
                            <li className="parsley-required">{validation['phone']}</li>
                          </ul>
                        :
                          null
                      }
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-6 col-xs-12">
                    <div className="form-group">
                      <label for="name">Gender</label>
                      <select 
                        className={ validation.length !== 0 && validation['gender'] ? 'form-control parsley-error' : 'form-control' }
                        required
                        onChange={(e) => setgender(e.target.value)}
                        defaultValue={gender}
                        value={gender}
                      >
                        <option disabled selected value="">Select Gender</option>
                        <option value="female">Female</option>
                        <option value="male">Male</option>
                      </select>
                      {
                        validation.length !== 0 
                        ?
                          <ul className="parsley-errors-list filled">
                            <li className="parsley-required">{validation['gender']}</li>
                          </ul>
                        :
                          null
                      }
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6 col-sm-6 col-xs-12">
                    <div className="form-group">
                      <label for="name">Join Date</label>
                      <input type="date" 
                        className={ validation.length !== 0 && validation['join_date'] ? 'form-control parsley-error' : 'form-control' }
                        name="name" 
                        required 
                        onChange={(e) => setjoindate(e.target.value)}
                        value={joindate}
                      />
                      {
                        validation.length !== 0 
                        ?
                          <ul className="parsley-errors-list filled">
                            <li className="parsley-required">{validation['join_date']}</li>
                          </ul>
                        :
                          null
                      }
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-6 col-xs-12">
                    <div className="form-group">
                      <label for="name">Status</label>
                      <select 
                        className={ validation.length !== 0 && validation['status'] ? 'form-control parsley-error' : 'form-control' }
                        required
                        onChange={(e) => setstatus(e.target.value)}
                        defaultValue={status}
                        value={status}
                      >
                        <option disabled selected value="">Select Status</option>
                        <option value="1">Active</option>
                        <option value="0">Deactive</option>
                        <option value="3" hidden>Blacklist</option>
                      </select>
                      {
                        validation.length !== 0 
                        ?
                          <ul className="parsley-errors-list filled">
                            <li className="parsley-required">{validation['status']}</li>
                          </ul>
                        :
                          null
                      }
                    </div>
                  </div>
                </div>

                <div className="form-group d-flex flex-column">
                  <label for="name">Haired By</label>
                  <Select
                    showSearch
                    placeholder="Hired by"
                    optionFilterProp="children"
                    onChange={(value) => sethaired(value)}
                    filterOption={(input, option) =>
                      (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                    }
                    options={listsAllAdviser}
                    value={haired}
                  />
                  {
                        validation.length !== 0 
                        ?
                          <ul className="parsley-errors-list filled">
                            <li className="parsley-required">{validation['haired']}</li>
                          </ul>
                        :
                          null
                      }
                </div>

                <div className="row">
                  <div className="col-md-2 col-sm-2 col-xs-2">
                    <div className="form-group">
                      <label for="name">NPWP</label><br/>
                      <Switch checked={(isnpwp === 1 ? true : false)} onChange={(e) => (e === true ? setisnpwp(1) : setisnpwp(0))} />
                      {/* {
                        isnpwp === 1 ?
                        :
                        <Switch onChange={(e) => (e === true ? setisnpwp(1) : setisnpwp(0))} />
                      } */}
                      {
                        validation.length !== 0 
                        ?
                          <ul className="parsley-errors-list filled">
                            <li className="parsley-required">{validation['npwp']}</li>
                          </ul>
                        :
                          null
                      }
                    </div>
                  </div>
                  <div className="col-md-2 col-sm-2 col-xs-2">
                    <div className="form-group">
                      <label for="name">Salary</label><br/>
                      <Switch checked={(issalary === 1 ? true : false)} onChange={(e) => (e === true ? setissalary(1) : setissalary(0))} />
                      {/* {
                        issalary === 1 ?
                        :
                        <Switch compact onChange={(e) => (e === true ? setissalary(1) : setissalary(0))} />
                      } */}
                      {
                        validation.length !== 0 
                        ?
                          <ul className="parsley-errors-list filled">
                            <li className="parsley-required">{validation['salary']}</li>
                          </ul>
                        :
                          null
                      }
                    </div>
                  </div>
                </div>
                <div className='mt-4'>
                  <button type="button" className="btn btn-secondary" onClick={() => setShowModalBlacklist(true)}>Blacklist</button>
                  <button type="submit" className="btn btn-primary pull-right">Submit</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Modal open={showModalBlacklist} title={'Blacklist Adviser'} onCancel={() => setShowModalBlacklist(false)} footer={null}>
          <div className='flex flex-col mb-6 gap-4 items-center'>
            <h2 className='mx-auto text-center'>Perhatian!</h2>
            <p className='mx-auto text-center'>Anda yakin ingin blacklist Adviser?</p>
            <div className='text-center'>
              <p>Alasan :</p>
              <TextArea 
              autoSize={{
                minRows: 3,
                maxRows: 5,
              }} 
              className='border' 
              value={blacklistReason}
              onChange={e => setBlacklistReason(e.target.value)}
              />
            </div>
          </div>
          <hr className='mb-4' />
          <div className='d-flex flex-row-reverse gap-4'>
            <button type="button" className="btn btn-primary" onClick={() => setShowModalBlacklist(false)} >Cancel</button>
            <button type="submit" className="btn btn-secondary" onClick={handleBlacklist}>Blacklist</button>
          </div>
      </Modal>
    </div>
  )
}

export default AdviserEdit
