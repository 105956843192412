import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { bankList } from '../../../store/actions/mitraActions'
import { officeList,  officeDelete } from '../../../store/actions/officeActions'
import { developerSave } from '../../../store/actions/developerActions'
import { Row, Select } from 'antd';

const DeveloperCreate = () => {
  const [name, setname] = useState('')
  const [email, setemail] = useState('')
  const [phone, setphone] = useState('')
  const [joindate, setjoindate] = useState('')
  const [status, setstatus] = useState('')
  const [offices, setoffices] = useState('')
  const [bank, setbank] = useState([])
  const [search, setsearch] = useState('')
  let dispatch = useDispatch()
  let dispatchOf = useDispatch()
  let history = useHistory()

  const { Option } = Select;


  useEffect(() => {
    dispatch(bankList())
    dispatchOf(officeList(1, search))
  }, [dispatch,dispatchOf])

  const dataofficeList = useSelector(state => state.officeList)
  const{ office, listsOffice } = dataofficeList

  const datadeveloperSave = useSelector(state => state.developerSave)
  const { validation } = datadeveloperSave

  const dataLogin = useSelector(state => state.login)
  const { userInfo } = dataLogin
  const created_by = userInfo.user.id

  const databankList = useSelector(state => state.bankList)
  const{ listsBank } = databankList

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(developerSave(name, email, phone, joindate, status, bank, offices, created_by, history))
  }

  const handleChange = selectedItems => {
    setbank(selectedItems);
  }


  return (
    <div className="">
      <div className="page-title">
        <div className="title_left">
          <h3>Create Developer</h3>
        </div>
      </div>
      <div className="clearfix"></div><br/>
      <div className="row">
        <div className="col-md-6 col-sm-6 col-xs-12">
          <div className="x_panel">
            <div className="x_title">
              <h2>Form Input</h2>
              <div className="clearfix"></div>
            </div>
            
            <div className="x_content">  
              <form onSubmit={(e) => handleSubmit(e)}>
                <div className="row">
                  <div className="col-md-6 col-sm-6 col-xs-12">
                    <div className="form-group">
                      <label for="name">Name</label>
                      <input type="text" 
                        className={ validation.length !== 0 && validation['name'] ? 'form-control parsley-error' : 'form-control' }
                        required 
                        onChange={(e) => setname(e.target.value)}
                      />
                      {
                        validation.length !== 0 
                        ?
                          <ul className="parsley-errors-list filled">
                            <li className="parsley-required">{validation['name']}</li>
                          </ul>
                        :
                          null
                      }
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-6 col-xs-12">
                    <div className="form-group">
                      <label for="name">Email</label>
                      <input type="email" 
                        className={ validation.length !== 0 && validation['email'] ? 'form-control parsley-error' : 'form-control' }
                        required 
                        onChange={(e) => setemail(e.target.value)}
                      />
                      {
                        validation.length !== 0 
                        ?
                          <ul className="parsley-errors-list filled">
                            <li className="parsley-required">{validation['email']}</li>
                          </ul>
                        :
                          null
                      }
                    </div>
                  </div>
                  
                </div>
                
                <div className="row">
                  <div className="col-md-6 col-sm-6 col-xs-12">
                    <div className="form-group">
                      <label for="name">Phone</label>
                      <input type="text" 
                        className={ validation.length !== 0 && validation['phone'] ? 'form-control parsley-error' : 'form-control' }
                        required 
                        onChange={(e) => setphone(e.target.value)}
                      />
                      {
                        validation.length !== 0 
                        ?
                          <ul className="parsley-errors-list filled">
                            <li className="parsley-required">{validation['phone']}</li>
                          </ul>
                        :
                          null
                      }
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-6 col-xs-12">
                    <div className="form-group">
                      <label for="name">Join Date</label>
                      <input type="date" 
                        className={ validation.length !== 0 && validation['join_date'] ? 'form-control parsley-error' : 'form-control' }
                        name="name" 
                        required 
                        onChange={(e) => setjoindate(e.target.value)}
                      />
                      {
                        validation.length !== 0 
                        ?
                          <ul className="parsley-errors-list filled">
                            <li className="parsley-required">{validation['join_date']}</li>
                          </ul>
                        :
                          null
                      }
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6 col-sm-6 col-xs-12">
                    <div className="form-group">
                      <label for="name">Status</label>
                      <select 
                        className={ validation.length !== 0 && validation['status'] ? 'form-control parsley-error' : 'form-control' }
                        required
                        onChange={(e) => setstatus(e.target.value)}
                      >
                        <option disabled selected value="">Select Status</option>
                        <option value="1">Active</option>
                        <option value="0">Deactive</option>
                      </select>
                      {
                        validation.length !== 0 
                        ?
                          <ul className="parsley-errors-list filled">
                            <li className="parsley-required">{validation['status']}</li>
                          </ul>
                        :
                          null
                      }
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-6 col-xs-12">
                    
                  </div>
                </div>
                <div className="form-group">
                  <label for="name">Bank</label>
                  <Select
                    mode="multiple"
                    value={bank}
                    size='large'
                    onChange={handleChange}
                    style={{ width: '100%' }}
                    options={listsBank}
                    optionFilterProp='label'
                  >
                  </Select>
                  {
                    validation.length !== 0 
                    ?
                      <ul className="parsley-errors-list filled">
                        <li className="parsley-required">{validation['bank']}</li>
                      </ul>
                    :
                      null
                  }
                </div>
                <div className='form-group mb-4'>
                  <label for="office">Select Loan Market Office</label>
                  <Select defaultValue="Kantor Loan Market" onChange={(e) => setoffices(e)} style={{ width: '100%' }} >
                    {(
                      listsOffice.length < '1' ? ''
                      :
                      listsOffice.map((item, index) => {
                        return(
                          <Option value={item.id} key={index}>{item.name}</Option>
                        )
                      })
                    )}
                    
                  </Select>
                </div>
                <button type="submit" className="btn btn-primary pull-right">Submit</button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DeveloperCreate
