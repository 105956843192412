import React, { useState, useEffect } from 'react'
import { Checkbox, Divider  } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { menuList, roleMYCRMSave } from '../../../../store/actions/menuActions'
import { useHistory } from "react-router-dom"

const RoleMYCRMCreate = () => {
  const [name, setname] = useState('')
  const [permission, setPermisson] = useState([])

  const dispatch = useDispatch()
  let history = useHistory();

  useEffect(() => {
    dispatch(menuList('MYCRM'))
  }, [dispatch])

  const allMenuList = useSelector(state => state.menuList)
  const { menu } = allMenuList

  const dataroleSave = useSelector(state => state.roleMYCRMSave)
  const { validation } = dataroleSave

  const onChangeCheked = (e, value) => {
    if (e.target.checked) {
      setPermisson(permission => permission.concat(value));
    }else{
      let filter = permission.filter((e) => e !== value );
      setPermisson(filter);
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(roleMYCRMSave(name, 'MYCRM', permission, history))
  }

  return (
    <div className="">
      <div className="page-title">
        <div className="title_left">
          <h3>Create Role MYCRM</h3>
        </div>
      </div>
      <div className="clearfix"></div><br/>
      <div className="row">
        <div className="col-md-12 col-sm-12 col-xs-12">
          <div className="x_panel">
            <div className="x_title">
              <h2>Form Input</h2>
              <div className="clearfix"></div>
            </div>
            
            <div className="x_content">  
              <form onSubmit={(e) => handleSubmit(e)}>
                <div className="row">
                  <div className="col-md-6 col-sm-12 form-group">
                      <label for="name">Name</label>
                      <input type="text" 
                        className={ validation.length !== 0 && validation['name'] ? 'form-control parsley-error' : 'form-control' }
                        name="name" 
                        required 
                        onChange={(e) => setname(e.target.value)} />
                      
                      {
                        validation.length !== 0 
                        ?
                          <ul className="parsley-errors-list filled">
                            <li className="parsley-required">{validation['name']}</li>
                          </ul>
                        :
                          null
                      }
                    </div>
                </div>
                <br/>
                {
                  menu.map((parent) => {
                    return(
                      <div key={parent.id}>
                        <div className="row">
                          <div className="col-md-12 col-sm-12 form-group">
                            <h2 id="glyphicons-glyphs">
                              <span style={{ marginRight:'20px' }}>{parent.name}</span>
                            </h2>
                          </div>
                          <div className="col-md-12 col-sm-12 form-group">
                            {
                              parent.permission.map((parentpermis) => {
                                return(
                                  <Checkbox key={parentpermis.toString()} onChange={(e) => onChangeCheked(e,parentpermis)}>{parentpermis}</Checkbox>
                                )
                              })
                            }
                            {
                              parent.child.map((child) => {
                                return(
                                  <div key={child.id} style={{ marginLeft: '25px', marginTop: '20px' }}>
                                    <div className="row">
                                      <div className="col-md-12 col-sm-12 form-group">
                                        <h2 id="glyphicons-glyphs">
                                          <span style={{ marginRight:'20px' }}>{child.name}</span>
                                        </h2>
                                      </div>
                                      <div className="col-md-12 col-sm-12 form-group">
                                      {
                                        child.permission.map((childpermis) => {
                                          return(
                                            <Checkbox key={childpermis.toString()} onChange={(e) => onChangeCheked(e,childpermis)}>{childpermis}</Checkbox>
                                          )
                                        })
                                      }
                                      </div>
                                    </div>  
                                  </div>
                                )
                              })
                            }
                          </div>
                        </div>
                        <Divider/>
                      </div>
                    )
                  })
                }
                <br/>
                <button type="submit" className="btn btn-primary pull-right">Submit</button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default RoleMYCRMCreate
