import React, { useState, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { childUpdate, childEdit, parentAllList } from '../../../../store/actions/menuActions'

const ChildEdit = () => {
  const [name, setname] = useState('')
  const [parent, setparent] = useState('')
  const [access, setaccess] = useState('')

  const dispatch = useDispatch()
  const history = useHistory()
  const id = localStorage.getItem('id_child');

  const datachildUpdate = useSelector(state => state.childUpdate)
  const { validation } = datachildUpdate

  const datachildEdit = useSelector(state => state.childEdit)
  const { child } = datachildEdit
  
  useEffect(() => {
    dispatch(childEdit(id))
    dispatch(parentAllList(access))
  }, [id, access])

  const dataparentAllList = useSelector(state => state.parentAllList)
  const { listsParent } = dataparentAllList

  useMemo(() => {
    if (child) {
      setname(child.name)
      setparent(child.id_parent)
      setaccess(child.access)
    }
  }, [child])

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(childUpdate(name, parent, access, id, history))
  }

  return (
    <div className="">
      <div className="page-title">
        <div className="title_left">
          <h3>Edit Child</h3>
        </div>
      </div>
      <div className="clearfix"></div><br/>
      <div className="row">
        <div className="col-md-6 col-sm-6 col-xs-12">
          <div className="x_panel">
            <div className="x_title">
              <h2>Form Input</h2>
              <div className="clearfix"></div>
            </div>
            
            <div className="x_content">  
              <form onSubmit={(e) => handleSubmit(e)}>
                <div className="form-group">
                  <label>Name</label>
                  <input type="text" 
                    className={ validation.length !== 0 && validation['name'] ? 'form-control parsley-error' : 'form-control' }
                    name="name" 
                    required 
                    onChange={(e) => setname(e.target.value)}
                    value={name}
                  />
                  
                  {
                    validation.length !== 0 
                    ?
                      <ul className="parsley-errors-list filled">
                        <li className="parsley-required">{validation['name']}</li>
                      </ul>
                    :
                      null
                  }
                </div>
                <div className="form-group">
                  <label>Access</label>
                  <input type="text" 
                    className={ validation.length !== 0 && validation['name'] ? 'form-control parsley-error' : 'form-control' }
                    name="name" 
                    disabled
                    value={access}
                  />
                  {
                    validation.length !== 0 
                    ?
                      <ul className="parsley-errors-list filled">
                        <li className="parsley-required">{validation['access']}</li>
                      </ul>
                    :
                      null
                  }
                </div>
                {
                  (access !== '' ? 
                      <div className='form-group'>
                        <label for="parent">Parent</label>
                        <select 
                          className={ validation.length !== 0 && validation['parent'] ? 'form-control parsley-error' : 'form-control' }
                          required onChange={(e) => setparent(e.target.value)} value={parent}>
                          <option disabled selected value="">Select Parent</option>
                          {
                            listsParent.map((item, index) => {
                              if (item.access === access) {
                                return(
                                  <option key={index} value={item.id}>{item.name}</option>
                                )
                              }
                            })
                          }
                        </select>
                        {
                          validation.length !== 0 
                          ?
                            <ul className="parsley-errors-list filled">
                              <li className="parsley-required">{validation['parent']}</li>
                            </ul>
                          :
                            null
                        }
                      </div>
                    :
                      null
                  )
                }
                <button type="submit" className="btn btn-primary pull-right">Submit</button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ChildEdit
