import { DEVELOPER_DELETE_FAIL, DEVELOPER_DELETE_REQUEST, DEVELOPER_DELETE_SUCCESS, DEVELOPER_EDIT_FAIL, DEVELOPER_EDIT_REQUEST, DEVELOPER_EDIT_SUCCESS, DEVELOPER_LIST_FAIL, DEVELOPER_LIST_REQUEST, DEVELOPER_LIST_SUCCESS, DEVELOPER_SAVE_FAIL, DEVELOPER_SAVE_REQUEST, DEVELOPER_SAVE_SUCCESS, DEVELOPER_SAVE_VALIDATION, DEVELOPER_UPDATE_FAIL, DEVELOPER_UPDATE_REQUEST, DEVELOPER_UPDATE_SUCCESS, DEVELOPER_UPDATE_VALIDATION , DEVELOPER_ALL_FAIL, DEVELOPER_ALL_REQUEST, DEVELOPER_ALL_SUCCESS,} from '../constants/developerConstants'
import { message } from 'antd'

const initialState = {
  validation: [],
}

const key = 'updatable';

//=================================  Developer  ============================================

export const developerListReducer = (state = {developer:[], listsDeveloper:[]}, action) => {
  switch (action.type) {
    case DEVELOPER_LIST_REQUEST:
      return{ ...state, loading: true, error: [] }
    case DEVELOPER_LIST_SUCCESS:
      return{ ...state, loading: false, developer: action.payload.data, listsDeveloper: action.payload.data.data }
    case DEVELOPER_LIST_FAIL:
      return{ ...state, loading: false, error: action.payload }
    default:
      return state
  }
}

export const developerAllReducer = (state = {developer:[], AllDeveloper:[]}, action) => {
  switch (action.type) {
    case DEVELOPER_ALL_REQUEST:
      return{ ...state, loading: true, error: [] }
    case DEVELOPER_ALL_SUCCESS:
      return{ ...state, loading: false, developer: action.payload.data, AllDeveloper: action.payload.data.data }
    case DEVELOPER_ALL_FAIL:
      return{ ...state, loading: false, error: action.payload }
    default:
      return state
  }
}

export const developerSaveReducer = (state = initialState, action) => {
  switch (action.type) {
    case DEVELOPER_SAVE_REQUEST:
      message.loading('Loading...', key);
      return{ ...state, loading: true, error: [], validation: [] }
    case DEVELOPER_SAVE_SUCCESS:
      message.success({ content: 'Insert success', key });
      return{ ...state, loading: false }
    case DEVELOPER_SAVE_VALIDATION:
      message.error({ content: 'Please fill all required field', key });
      return{ ...state, loading: false, validation: action.payload }
    case DEVELOPER_SAVE_FAIL:
      message.error({ content: action.payload, key });
      return{ ...state, loading: false, error: action.payload }
    default:
      return state
  }
}

export const developerEditReducer = (state = {developer:{}}, action) => {
  switch (action.type) {
    case DEVELOPER_EDIT_REQUEST:
      return{ ...state, loading: true, error: [] }
    case DEVELOPER_EDIT_SUCCESS:
      return{ ...state, loading: false, developer: action.payload.data }
    case DEVELOPER_EDIT_FAIL:
      return{ ...state, loading: false, error: action.payload }
    default:
      return state
  }
}

export const developerUpdateReducer = (state = initialState, action) => {
  switch (action.type) {
    case DEVELOPER_UPDATE_REQUEST:
      message.loading('Loading...', key);
      return{ ...state, loading: true, error: [], validation: [] }
    case DEVELOPER_UPDATE_SUCCESS:
      message.success({ content: 'Update success', key });
      return{ ...state, loading: false }
    case DEVELOPER_UPDATE_VALIDATION:
      message.error({ content: 'Please fill all required field', key });
      return{ ...state, loading: false, validation: action.payload }
    case DEVELOPER_UPDATE_FAIL:
      message.error({ content: action.payload, key });
      return{ ...state, loading: false, error: action.payload }
    default:
      return state
  }
}

export const developerDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case DEVELOPER_DELETE_REQUEST:
      message.loading('Loading...', key);
      return{ ...state, loading: true, error: [] }
    case DEVELOPER_DELETE_SUCCESS:
      message.success({ content: 'Delete success', key });
      return{ ...state, loading: false }
    case DEVELOPER_DELETE_FAIL:
      message.error({ content: action.payload, key });
      return{ ...state, loading: false, error: action.payload }
    default:
      return state
  }
}

